<template>
    <!--
      <mainInputByType
        v-model="value"
        @update:modelValue="update()"
      />
    -->
    <div>
      <v-form v-model="valid"
        v-if="!readonly"
      >
        <v-text-field
          v-if="[1].includes(typ)"
          type="text"
          :label="label"
          v-model="value"
          :rules="[
          ]"
          @keyup="set()"
        />
        <v-textarea
          v-if="[2].includes(typ)"
          :label="label"
          v-model="value"
          :rules="[
          ]"
          @keyup="set()"
        />
        <v-text-field
          v-if="[3].includes(typ)"
          type="number"
          :label="label"
          v-model="value"
          :rules="[
            $store.state.main.inputValidationRules.nummer,
          ]"
          @keyup="set()"
        />
        <v-text-field
          v-if="[4].includes(typ)"
          type="email"
          :label="label"
          v-model="value"
          :rules="[
            $store.state.main.inputValidationRules.email,
          ]"
          @keyup="set()"
        />
        <v-text-field
          v-if="[5].includes(typ)"
          type="text"
          :label="label"
          v-model="value"
          :rules="[
            $store.state.main.inputValidationRules.telefon,
          ]"
          @keyup="set()"
        />
        <v-text-field
          v-if="[6].includes(typ)"
          type="text"
          :label="label"
          v-model="value"
          :rules="[
            $store.state.main.inputValidationRules.url,
          ]"
          @keyup="set()"
        />
        <v-text-field
          v-if="[7].includes(typ)"
          type="date"
          :label="label"
          v-model="value"
          :rules="[
            // $store.state.main.inputValidationRules.datum,
          ]"
          @update:modelValue="set()"
        />
        <v-text-field
          v-if="[8].includes(typ)"
          type="time"
          :label="label"
          v-model="value"
          :rules="[
          ]"
          @update:modelValue="set()"
        />
        <v-select
          v-if="[9, 10].includes(typ)"
          :multiple="[10].includes(typ)"
          :label="label"
          v-model="value"
          @update:modelValue="set()"
          :items="selectoptions"
          item-title="wert"
          item-value="id"
        />
      </v-form>
      <div v-else class="px-2">
        <div style="font-size:80%;">{{ label }}</div>
        <p class="mx-1">{{ value !== '' ? value : '-' }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'mainInputByType',
    
    props: {
      modelValue: {
        type: [String, Number, Array],
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      typ: {
        type: Number,
        default: 1,
      },
      selectoptions: {
        type: Array,
        default() { return [] },
      },
      readonly: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      valid: null,
      value: undefined,
    }),
    watch: {
      modelValue: {
        handler(neu) {
          this.setValue(neu);
        },
        deep: true,
      },
    },
    methods: {
      setValue(value) {
        let val = value;
        if ([10].includes(this.typ) && !Array.isArray(val)) {
          val = val.split(',');
          val.forEach((v, i) => {
            val[i] = parseInt(v, 10);
            if (!val[i]) {
              val.splice(i, 1);
            }
          });
          if (val.length <= 0 && this.readonly) {
            val = '';
          } else if (val.length > 0 && this.readonly) {
            const neuVal = [];
            val.forEach((v) => {
              if (this.selectoptions.filter((o) => o.id === v).length > 0) {
                neuVal.push(this.selectoptions.filter((o) => o.id === v)[0].wert);
              }
            });
            val = neuVal.join(', ');
          }
        } else if ([9].includes(this.typ)) {
          val = parseInt(val, 10);
          if (!val) {
            if (this.readonly) {
              val = '';
            } else {
              val = [];
            }
          } else if (this.readonly) {
            if (this.selectoptions.filter((o) => o.id === val).length > 0) {
              val = this.selectoptions.filter((o) => o.id === val)[0].wert;
            }
          }
        }
        this.value = val;
      },
      set() {
        let val = this.value;
        if (Array.isArray(val)) {
          val.forEach((v, i) => {
            if (!v) {
              val.splice(i, 1);
            }
          });
        }
        this.$emit('update:modelValue', val);
      },
    },
    created() {
      this.setValue(this.modelValue);
    },
  }
  </script>
  