// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { VDataTable } from 'vuetify/labs/VDataTable'
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader'

export default createVuetify({
  components: {
    VDataTable,
    VSkeletonLoader,
  },
  defaults: {
    VCard: {
      style: '',
    },
    VCardText: {
      class: 'pa-2',
    },
    VDataTable: {
      density: 'compact',
      itemsPerPageOptions: [
        {value: 10, title: '10'},
        {value: 25, title: '25'},
        {value: 50, title: '50'},
        {value: -1, title: 'Alle'},
      ],
      itemsPerPageText: 'Einträge pro Seite:',
      pageText: '{0} bis {1} von {2}',
      loadingText: 'Lade...',
      noDataText: 'Keine Daten gefunden',
      sortByText: 'Sortiere nach',
    },
    VListItem: {
      style: '',
    },
    VRow: {
      dense: true,
    },
    VSelect: {
      noDataText: 'Keine Einträge gefunden',
    },
    VTabs: {
      density: 'compact',
    },
    VTextField: {
      density: 'compact',
    },
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#ffffff',
          secondary: '#424242',
          text: '#111111',
          'text-invert': '#ffffff',
          background: '#ffffff',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
          error: '#B00020',
        },
      },
      test: {
        dark: false,
        colors: {
          primary: '#0000ff',
          secondary: '#424242',
          text: '#ff0000',
          'text-invert': '#00ff00',
          background: '#00ff00', //#dce8fa
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
          error: '#B00020',
        },
      },
    },
  },
});
