<template>
    <!--
      <c001001001Upload
      />
    -->
    <v-dialog
      v-model="dialogOpen"
      width="800px"
    >
      <v-card
        :style="`background-color:${$vuetify.theme.current.colors['background']};color:${$vuetify.theme.current.colors['background-text']};`"
      >
        <v-card-actions
          :style="`background-color:${$vuetify.theme.current.colors['primary']};color:${$vuetify.theme.current.colors['primary-text']};`"
          class="ps-4"
        >
          Datei/en hochladen
          <v-spacer />
          <v-btn icon
            @click="cancel()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-file-input
            chips
            multiple
            label="Datei/en auswählen"
            v-model="files"
            @update:model-value="selected()"
          ></v-file-input>
          <div class="d-flex justify-space-around">
            <div style="width:40%;"><v-btn block
              @click="cancel()"
              color="grey"
              :disabled="loadingSave"
            >Abbrechen</v-btn></div>
            <div style="width:40%;"><v-btn block
              :color="$vuetify.theme.current.colors['primary']"
              :style="`color:${$vuetify.theme.current.colors['primary-text']};`"
              :loading="loadingSave"
              :disabled="files.length <= 0"
              @click="tryUpload()"
            >Speichern</v-btn></div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  
  <script>

  export default {
    name: 'c001001001Upload',
    
    components: {
    },
    props: {
      idVerz: {
        type: Number,
        default: 0,
      },
      eventOpenDialog: {
        type: Number,
        default: null,
      },
    },
    data: () => ({
      dialogOpen: false,
      loadingSave: false,
      files: [],
    }),
    watch: {
      eventOpenDialog() {
        this.dialogOpen = true;
      },
    },
    methods: {
      cancel() {
        this.dialogOpen = false;
      },
      selected() {
        // console.log(this.files);
      },
      tryUpload() {
        this.loadingSave = true;
        const data = {};
        this.files.forEach((file, i) => {
          data[`file${i}`] = file;
        });
        data.idVerz = this.idVerz;
        this.$weFetch({
          path: '001-001/?001&upload',
          data,
          timeout: 128000,
        }).then(() => {
          this.$store.dispatch('s001001/tryGetData').then(() => {
            this.files = [];
            this.loadingSave = false;
            this.cancel();
          });
        });
      },
    },
    created() {
    },
  }
  </script>
  