<template>
    <!--
      <c001001001EditVerz
        v-model="value"
        @update:modelValue="update()"
      />
    -->
    <v-dialog
      v-model="dialogDelete"
      width="400px"
    >
      <v-card
        :style="`background-color:${$vuetify.theme.current.colors['background']};color:${$vuetify.theme.current.colors['background-text']};`"
      >
        <v-card-title
          :style="`background-color:${$vuetify.theme.current.colors['primary']};color:${$vuetify.theme.current.colors['primary-text']};`"
          class="ps-4"
        >
          Wirklich löschen?
        </v-card-title>
        <v-card-text>
          Die Datei "{{ value?.name }}" wird unwiederruflich gelöscht.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialogDelete = false" variant="text">abbrechen</v-btn>
          <v-spacer />
          <v-btn variant="text" :color="$vuetify.theme.current.colors['error']" @click="tryDel()" :loading="loadingDel">Löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogOpen"
      width="500px"
      transition="dialog-bottom-transition"
    >
      <v-card
        :style="`background-color:${$vuetify.theme.current.colors['background']};color:${$vuetify.theme.current.colors['background-text']};`"
      >
        <v-card-actions
          :style="`background-color:${$vuetify.theme.current.colors['primary']};color:${$vuetify.theme.current.colors['primary-text']};`"
          class="ps-4"
        >
          Datei bearbeiten
          <v-spacer />
          <v-btn icon
            :disabled="value?.id <= 0"
            @click="dialogDelete = true"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn icon
            @click="cancel()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-form
            v-if="value"
            v-model="valid"
          >
            <v-text-field
              label="Dateiname"
              v-model="value.name"
              :rules="[
                $store.state.main.inputValidationRules.filename,
              ]"
            />
            <div class="d-flex justify-space-around">
              <div style="width:40%;"><v-btn block
                @click="cancel()"
                color="grey"
                :disabled="loadingSave"
              >Abbrechen</v-btn></div>
              <div style="width:40%;"><v-btn block
                :color="$vuetify.theme.current.colors['primary']"
                :style="`color:${$vuetify.theme.current.colors['primary-text']};`"
                :loading="loadingSave"
                :disabled="!valid"
                @click="trySave()"
              >Speichern</v-btn></div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  
  <script>

  export default {
    name: 'c001001001EditFile',
    
    components: {
    },
    props: {
      modelValue: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data: () => ({
      dialogOpen: false,
      dialogDelete: false,
      loadingSave: false,
      loadingDel: false,
      value: '',
      valid: true,
    }),
    watch: {
      modelValue: {
        handler(neu) {
          const val = JSON.parse(JSON.stringify(neu));
          this.setValue(val);
        },
        deep: true,
      },
    },
    methods: {
      setValue(value) {
        this.value = value;
        this.checkDialogOpenCloseByValue();
      },
      checkDialogOpenCloseByValue() {
        if (this.value?.id >= 0) {
          this.dialogOpen = true;
        } else {
          this.dialogOpen = false;
        }
      },
      cancel() {
        this.value = undefined;
        this.checkDialogOpenCloseByValue();
      },
      set() {
        this.$emit('update:modelValue', this.value);
      },
      trySave() {
        this.loadingSave = true;
        this.$weFetch({
          path: '001-001/?001&saveFile',
          data: {
            obj: JSON.stringify(this.value),
          },
        }).then(() => {
          this.$store.dispatch('s001001/tryGetData').then(() => {
            this.loadingSave = false;
            this.cancel();
          });
        });
      },
      tryDel() {
        this.loadingDel = true;
        this.$weFetch({
          path: '001-001/?001&delFile',
          data: {
            id: this.value.id,
          },
        }).then(() => {
          this.$store.dispatch('s001001/tryGetData').then(() => {
            this.loadingDel = false;
            this.dialogDelete = false;
            this.cancel();
          });
        });
      },
    },
    created() {
      this.setValue(this.modelValue);
    },
  }
  </script>
  