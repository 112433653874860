<template>
  <div>
    <v-container fluid align="center" v-if="currentTab === '001'" class="pa-0">
      <mainManageObj
        typ="user"
        typname="Benutzer"
        typnameKeine="Kein Benutzer"
        :typNeu="$store.state.s000002.settings.neuUser"
        delPath="000-002/?001&delUser"
        delSuccessDispatch="login/tryGetSettings"
        savePath="000-002/?001&saveUser"
        saveSuccessDispatch="login/tryGetSettings"
        :dataTableItems="$store.state.login.app.users"
        :idsDontDelete="[0, $store.state.login.user.id]"
        :dataTableHeaders="[
          {
            title: 'Nutzername',
            align: 'start',
            sortable: true,
            key: 'nutzername',
          },
          {
            title: 'Name',
            align: 'start',
            sortable: true,
            key: 'name',
          },
          {
            title: '',
            align: 'start',
            sortable: false,
            key: 'action',
          },
        ]"
      />
    </v-container>
    <v-container fluid align="center" v-if="currentTab === '002'" class="pa-0">
      <mainManageObj
        typ="param"
        typname="Nutzerparameter"
        typnameKeine="Keine Nutzerparameter"
        :typNeu="$store.state.s000002.settings.neuParam"
        delPath="000-002/?002&delParam"
        delSuccessDispatch="login/tryGetSettings"
        savePath="000-002/?002&saveParam"
        saveSuccessDispatch="login/tryGetSettings"
        :dataTableItems="$store.state.login.app.nutzerparameter"
        :idsDontDelete="[0, 1, 2, 3]"
        :dataTableHeaders="[
          {
            title: 'Parametername',
            align: 'start',
            sortable: true,
            key: 'name',
          },
          {
            title: '',
            align: 'start',
            sortable: false,
            key: 'action',
          },
        ]"
      />
    </v-container>
    <v-container fluid align="center" v-if="currentTab === '003'" class="pa-0">
      <mainManageObj
        typ="grp"
        typname="Gruppe"
        typnameKeine="Keine Gruppe"
        :typNeu="$store.state.s000002.settings.neuGrp"
        delPath="000-002/?003&delGrp"
        delSuccessDispatch="login/tryGetSettings"
        savePath="000-002/?003&saveGrp"
        saveSuccessDispatch="login/tryGetSettings"
        :dataTableItems="$store.state.login.app.nutzergruppen"
        :idsDontDelete="[0, 1]"
        :dataTableHeaders="[
          {
            title: '#',
            align: 'start',
            sortable: true,
            key: 'prio',
          },
          {
            title: 'Gruppenname',
            align: 'start',
            sortable: true,
            key: 'nameNachPrio',
          },
          {
            title: '',
            align: 'start',
            sortable: false,
            key: 'action',
          },
        ]"
      />
    </v-container>
    <v-container fluid v-if="currentTab === 'set'" class="pa-0">
      Einstellungen
    </v-container>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import mainManageObj from '@/components/main/mainManageObj.vue';

export default defineComponent({
  name: 'v000-002-Benutzerverwaltung',

  components: {
    mainManageObj,
  },
  data: () => ({
  }),
  computed: {
    currentTab() {
      return this.$router.currentRoute.value.params.id;
    },
    idSeite() {
      return this.$router.currentRoute.value.path.substring(1, 8);
    },
    seite() {
      return Object.values(this.$store.state.login.user.seiten).filter((o) => o.id === this.idSeite)[0];
    },
    tabs() {
      return Object.values(this.seite.rechte).filter((o) => o.id.length === 3);
    },
  },
  watch: {
    currentTab() {
      this.$store.dispatch('s000002/tryGetData');
    },
  },
  methods: {
  },
  created() {
    this.$store.dispatch('s000002/tryGetData');
  },
});
</script>
