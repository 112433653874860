<template>
  <div>
    <v-navigation-drawer
      v-model="showNavigationDrawer"
      location="left"
      temporary
      :style="`background-color:${$vuetify.theme.current.colors['background']};color:${$vuetify.theme.current.colors['background-text']};`"
    >
      <div class="d-flex flex-column" style="min-height:100%;">
        <div :style="`background-color:${$vuetify.theme.current.colors['primary']};color:${$vuetify.theme.current.colors['primary-text']};`">
          <v-list>
            <v-list-item :style="`background-color:${$vuetify.theme.current.colors['primary']};color:${$vuetify.theme.current.colors['primary-text']};`">
              {{ $store.state.login.app.titel }}
            </v-list-item>
            <v-list-item
              v-if="$store.state.login.app.untertitel !== ''"
              density="compact"
              :style="`font-size:80%;transform:translateY(-10px);background-color:${$vuetify.theme.current.colors['primary']};color:${$vuetify.theme.current.colors['primary-text']};`"
            >
              {{ $store.state.login.app.untertitel }}
            </v-list-item>
            <v-list-item
              v-for="meinProfil in Object.values($store.state.login.user.seiten).filter((o) => o.id === '000-005' && o.quicknav === 0)"
              :key="meinProfil.id"
              density="compact"
              :to="`/${meinProfil.id}-001`"
              :active="$router.currentRoute.value.fullPath.includes(`/${meinProfil.id}-`)"
              :style="`background-color:${$vuetify.theme.current.colors['primary']};color:${$vuetify.theme.current.colors['primary-text']};`"
              :class="($store.state.login.user.name !== $store.state.login.user.nutzername) ? 'pb-6' : ''"
            >
              <v-icon start>mdi-account</v-icon>
              {{ $store.state.login.user.name }}
              <div
                v-if="$store.state.login.user.name !== $store.state.login.user.nutzername"
                style="position:absolute;left:52px;bottom:4px;font-size:80%;"
              >
                {{ $store.state.login.user.nutzername }}
              </div>
            </v-list-item>
          </v-list>
        </div>
        <div>
          <v-row no-gutters>
            <v-col>
              <v-btn
                size="small" block style="border-radius:0px;"
                @click="
                  showNavigationDrawer = false;
                  $store.dispatch('login/tryLogout', { router: $router });
                "
              >
                <v-icon start>mdi-logout-variant</v-icon>
                Ausloggen
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                size="small" block style="border-radius:0px;"
                v-if="!$store.state.main.offline"
                :style="`background-color:${$vuetify.theme.current.colors['success']};color:${$vuetify.theme.current.colors['success-text']};`"
                @click="$store.commit('main/setOfflineModus', { val: true });"
              >
                <v-icon start>mdi-wifi</v-icon>
                Online
              </v-btn>
              <v-btn
                size="small" block style="border-radius:0px;"
                v-if="$store.state.main.offline"
                :style="`background-color:${$vuetify.theme.current.colors['error']};color:${$vuetify.theme.current.colors['error-text']};`"
                @click="$store.commit('main/setOfflineModus', { val: false });"
              >
                <v-icon start>mdi-wifi-off</v-icon>
                Offline
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-list class="mt-4" v-if="$store.state.login.user.code">
          <v-list-item
            v-for="seite in Object.values($store.state.login.user.seiten).filter((o) => o.quicknav === 0 && o.id.includes('-') && o.id !== '000-005')"
            :key="seite.id"
            :to="`/${seite.id}-001`"
            :active="$router.currentRoute.value.fullPath.includes(`/${seite.id}-`)"
          >
            <v-list-item-title>
              <v-icon start>{{ seite.icon }}</v-icon>
              {{ seite.titel }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
    <Transition name="fade">
      <v-app-bar
        v-if="$store.state.login.user.code"
        :scroll-behavior="$vuetify.display.mdAndUp ? '' : 'elevate'"
        :elevation="$vuetify.display.mdAndUp ? 1 : 0"
        density="compact"
        :color="$vuetify.theme.current.colors['primary']"
        :style="`color:${$vuetify.theme.current.colors['primary-text']};`"
        class="d-flex justify-space-between"
      >
        <v-app-bar-nav-icon
          variant="text"
          class="ms-0"
          @click.stop="showNavigationDrawer = !showNavigationDrawer"
        />
        <v-tabs
          center-active
          :show-arrows="
            !isTouch
          "
          :hide-slider="(Object.values($store.state.login.user.seiten).filter((o) => ($router.currentRoute.value.fullPath.includes(`/${o.id}-`) && o.quicknav === 1)).length <= 0)"
        >
          <v-tab
            v-for="seite in Object.values($store.state.login.user.seiten).filter((o) => o.quicknav === 1)"
            :key="seite.id"
            size="small"
            :to="`/${seite.id}-001`"
            :active="$router.currentRoute.value.fullPath.includes(`/${seite.id}-`)"
          >
            <v-icon start>{{ seite.icon }}</v-icon>
            {{ seite.titel }}
          </v-tab>
        </v-tabs>
      </v-app-bar>
    </Transition>
    <Transition name="fade">
      <v-app-bar
        v-if="
          $store.state.login.user.code
          && $vuetify.display.mdAndUp
          && bottomNavItems.length > 1
        "
        scroll-behavior="elevate"
        density="compact"
        :color="$vuetify.theme.current.colors['primary-lighten-2']"
        :style="`color:${$vuetify.theme.current.colors['primary-lighten-2-text']};`"
      >
        <v-tabs
          center-active
        >
          <v-tab
            v-for="item in bottomNavItems"
            :key="item.id"
            size="small"
            :to="`/${idSeite}-${item.id}`"
            :active="$router.currentRoute.value.fullPath.includes(`/${seite.id}-${item.id}`)"
          >
            <v-icon start>{{ item.icon }}</v-icon>
            {{ item.titel }}
          </v-tab>
        </v-tabs>
      </v-app-bar>
    </Transition>
    <Transition name="fade">
      <v-bottom-navigation
        v-if="
          $store.state.login.user.code
          && !$vuetify.display.mdAndUp
          && bottomNavItems.length > 1
        "
        grow
        density="comfortable"
        :bg-color="$vuetify.theme.current.colors['primary-lighten-2']"
        :style="`color:${$vuetify.theme.current.colors['primary-lighten-2-text']};`"
      >
        <v-tabs
          center-active hide-slider
          density="comfortable"
        >
          <v-tab
            v-for="item in bottomNavItems"
            :key="item.id"
            stacked size="x-small"
            :to="`/${idSeite}-${item.id}`"
            :active="$router.currentRoute.value.fullPath.includes(`/${seite.id}-${item.id}`)"
          >
            <v-icon start>{{ item.icon }}</v-icon>
            {{ item.titel }}
          </v-tab>
        </v-tabs>
        <!-- <v-btn
          v-for="item in bottomNavItems"
          :key="item.id"
          :to="`/${idSeite}-${item.id}`">
          <v-icon>{{ item.icon }}</v-icon>
          {{ item.titel }}
        </v-btn> -->
      </v-bottom-navigation>
    </Transition>
  </div>
</template>

<script>

export default {
  name: 'layoutNavigation',
  props: {
    html: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    showNavigationDrawer: false,
    items: [
      {
        title: 'Foo',
        value: 'foo',
      },
      {
        title: 'Bar',
        value: 'bar',
      },
      {
        title: 'Fizz',
        value: 'fizz',
      },
      {
        title: 'Buzz',
        value: 'buzz',
      },
    ],
  }),
  computed: {
    isTouch() {
      return (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0));
    },
    idSeite() {
      return this.$store.state.login.navigation.idSeite;
    },
    seite() {
        return this.$store.state.login.navigation.seite; 
    },
    bottomNavItems() {
      return this.$store.state.login.navigation.bottomNavItems;
    },
  },
}
</script>
