export default {
  install: (app) => {
    app.config.globalProperties.$weFetch = function weFetch({ path, method, data, timeout, delay, showNetworkAlert, networkErrorOfflineMode }) {
      const date = new Date();
      const fetchId = date.getTime();
      this.$store.commit('main/startNewFetch', { id: fetchId });
      const myPromise = new Promise((resolve) => {
        const body = new FormData();
        if (data) {
          Object.keys(data).forEach((key) => {
            body.append(key, data[key]);
          });
        }
        if (this.$store.state.login.user.code) {
          body.append('code', this.$store.state.login.user.code);
        }
        if (this.$store.state.main.offline) {
          timeout = 1;
          path = 'vLogin/?getSettings';
        }
        if (!path) { path = 'vLogin/?getSettings'; }
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout || this.$store.state.main.fetchMaxTimeout);
        fetch(process.env.VUE_APP_API_HOST+'/'+path, {
          method: method || 'post',
          body,
          signal: controller.signal,
        })
          .then((response) => {
            clearTimeout(id);
            return response.json();
          })
          .then((res) => {
            if (this.$store.state.main.offline) {
              throw new Error;
            } else {
              setTimeout(() => {
                this.$store.commit('main/endFetch', { id: date.getTime() });
                // Deaktiviere den Offline-Modus:
                this.$store.commit('main/setOfflineModus', { val: false });
                if (res.data) {
                  if (res.data.$_USER) {
                    localStorage.setItem('$_USER', JSON.stringify(res.data.$_USER));
                    this.$store.commit('login/setUserAndApp', { 
                      user: JSON.parse(localStorage.getItem('$_USER')),
                    });
                  }
                  if (res.data.$_APP) {
                    localStorage.setItem('$_APP', JSON.stringify(res.data.$_APP));
                    this.$store.commit('login/setUserAndApp', { 
                      app: JSON.parse(localStorage.getItem('$_APP')),
                    });
                    let newTitle = document.title.split(' - ');
                    newTitle[0] = res.data.$_APP.titel;
                    document.title = newTitle.join(' - ');
                    // document.title = document.title.split('...').join(res.data.$_APP.titel);
                  }
                  if (res.data.API_V) {
                    let localAPI_V = '';
                    if (localStorage.getItem('API_V')) {
                      localAPI_V = localStorage.getItem('API_V');
                    }
                    if (
                      localAPI_V !== ''
                      && localAPI_V !== res.data.API_V
                    ) {
                      document.getElementById('main_loading_update').classList.add('show');
                      document.getElementById('automaticUpdateInfo').classList.add('show');
                      setTimeout(() => {
                        window.location.reload();
                      }, 1500);
                      localStorage.setItem('API_V', res.data.API_V);
                    }
                  }
                }
                // Fehlermeldungen
                if (res.status === 'errorLogin') {
                  this.$store.commit('main/showSnackbar', {
                    text: '<b>Fehler!</b><br>Falsche Zugangsdaten',
                  });
                } else if (res.status === 'errorLoginAccountNotApplied') {
                  this.$store.commit('main/showSnackbar', {
                    text: '<b>Achtung!</b><br>Die Registrierung dieses Benutzerkontos wurde noch nicht bestätigt. Bitte später erneut versuchen. Es wird per E-Mail darüber informiert, sobald die Bestätigung erfolgt ist.',
                  });
                } else if (res.status === 'errorCountLogin') {
                  this.$store.commit('main/showSnackbar', {
                    text: '<b>Achtung!</b><br>Zu viele falsche Anmeldeversuche. Bitte später erneut versuchen.',
                  });
                } else if (res.status === 'errorAuthKeyInvalid') {
                  localStorage.clear();
                  this.$store.commit('main/showSnackbar', {
                    text: '<b>Zugriff verweigert!</b>',
                  });
                  this.$store.dispatch('login/tryLogout', { router: this.$router, hideAlert: true });
                } else if (res.status === 'errorAuthKeyExpired') {
                  this.$store.commit('main/showSnackbar', {
                    text: '<b>Achtung!</b> Aus Sicherheitsgründen wurde die Sitzung, aufgrund von Inaktivität, beendet.',
                  });
                  this.$store.dispatch('login/tryLogout', { router: this.$router, hideAlert: true });
                } else if (res.status === 'errorAuthNoAccess') {
                  this.$store.commit('main/showSnackbar', {
                    text: '<b>Fehler!</b> Keine Zugriffsberechtigung.',
                  });
                  this.$store.dispatch('login/tryLogout', { router: this.$router });
                }
                // View-Spezifische Funktionen
                if (
                  path === 'vLogin/?getSettings'
                  && res.status === 'success'
                ) {
                  localStorage.setItem('vLogin-settings', JSON.stringify(res.data.settings));
                }
                if (
                  path === 'vLogin/?doLogin'
                  && res.status === 'success'
                ) {
                  // Erfolgreich eingeloggt
                  this.$router.push({ path: '/000-000-001' });
                }
                resolve(res);
              }, delay || 1);
            }
          })
          .catch((e) => {
            this.$store.commit('main/endFetch', { id: fetchId });
            // Fehler oder timeout
            if (
              this.$router.currentRoute.value.fullPath !== '/'
            ) {
              if (!networkErrorOfflineMode && networkErrorOfflineMode !== false) {
                // Wenn die Standardeinstellungen geladen werden und man sich nicht auf der Login-Seite befindet,
                // aktiviere Offline-Modus
                this.$store.commit('main/setOfflineModus', { val: true });
              }
              if (showNetworkAlert === true) {
                this.$store.commit('main/showSnackbar', {
                  text: '<b>Netzwerkfehler</b> Konnte keine Verbindung zu Server aufbauen',
                  color: 'error',
                });
              }
            } else {
              // Sonst zeige einen Verbindungsfehler an
              this.$store.commit('main/showSnackbar', {
                text: '<b>Verbindungsfehler</b>',
                color: 'error',
              });
            }
            resolve({
              status: 'error',
              log: e,
            });
          });
      });
      return myPromise;
    };

    app.config.globalProperties.$weUpdatePrimaryColor = function weUpdatePrimaryColor(getColor) {
      let color = getColor;
      if (!getColor) {
        color = this.$store.state.login.app['theme-light-primary'];
      }
      if (color) {
        const $vuetify = this.$vuetify;
        $vuetify.theme.current.colors['primary'] = color;
        Object.keys($vuetify.theme.current.colors).forEach((name) => {
          if (['primary', 'secondary', 'text', 'text-invert', 'error', 'success', 'warning'].includes(name)) {
            const hexColor = $vuetify.theme.current.colors[name];
            for (let faktor = -9;faktor <= 9;faktor += 1) {
              let red = Math.min(255, parseInt(hexColor.slice(1, 3), 16) + 30*(faktor));
              if (parseInt(red, 10) < 0) { red = 0; }
              let green = Math.min(255, parseInt(hexColor.slice(3, 5), 16) + 30*(faktor));
              if (parseInt(green, 10) < 0) { green = 0; }
              let blue = Math.min(255, parseInt(hexColor.slice(5, 7), 16) + 30*(faktor));
              if (parseInt(blue, 10) < 0) { blue = 0; }
              const newHexColor = `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
              
              const relativeLuminance = 0.2126 * red/255 + 0.7152 * green/255 + 0.0722 * blue/255;
              let fontColor = $vuetify.theme.current.colors['text'];
              if (relativeLuminance <= 0.5) { fontColor = $vuetify.theme.current.colors['text-invert']; }
              
              if (faktor > 0) {
                $vuetify.theme.current.colors[name+'-lighten-'+faktor] = newHexColor;
                $vuetify.theme.current.colors[name+'-lighten-'+faktor+'-text'] = fontColor;
              } else if (faktor < 0) {
                $vuetify.theme.current.colors[name+'-darken-'+(faktor * -1)] = newHexColor;
                $vuetify.theme.current.colors[name+'-darken-'+(faktor * -1)+'-text'] = fontColor;
              } else {
                $vuetify.theme.current.colors[name+'-text'] = fontColor;
              }
            }
          }
        });
        setTimeout(() => {
          if (this.$store.state.login.app['theme-light-background'] !== '') {
            $vuetify.theme.current.colors['background'] = this.$store.state.login.app['theme-light-background'];
          } else {
            $vuetify.theme.current.colors['background'] = $vuetify.theme.current.colors['primary-lighten-7'];
          }
          $vuetify.theme.currentTheme = { ...$vuetify.theme.currentTheme };
          this.$store.state.login.user = JSON.parse(JSON.stringify(this.$store.state.login.user));
          
          $vuetify.defaults.VListItem.style = `background-color:${$vuetify.theme.current.colors['background']};color:${$vuetify.theme.current.colors['text']};`;
          $vuetify.defaults.VCard.style = `background-color:${$vuetify.theme.current.colors['background']};color:${$vuetify.theme.current.colors['text']};`;
        }, 1);
        //$vuetify.theme.dark = !$vuetify.theme.dark;
        //$vuetify.theme.dark = !$vuetify.theme.dark;
        // this.output = output;
        // console.log($vuetify.theme.current.colors);
      }
    };
  
    app.config.globalProperties.$weCreateCode = function weCreateCode({ length, useChars, useLUndI, useNumbers, useSymbols }) {
      const chars = 'abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ';
      const lUndI = 'lI';
      const numbers = '0123456789';
      const symbols = '-_&!%$';
      let elements = '';
      if (!length) { length = 10; }
      if (useChars || (!useChars && useChars !== false)) { elements += chars; }
      if (useLUndI || (!useLUndI && useLUndI !== false)) { elements += lUndI; }
      if (useNumbers || (!useNumbers && useNumbers !== false)) { elements += numbers; }
      if (useSymbols || (!useSymbols && useSymbols !== false)) { elements += symbols; }
      elements = elements.split('');
      let result = '';
      if (elements.length > 0) {
        for (let i = 1; i <= length; i += 1) {
          const random = Math.floor(Math.random() * (elements.length));
          result += elements[random];
        }
      }
      return result;
    };
  },
};