// import app from '@/main.js';

export default {
  namespaced: true,
  state: {
    loading: {
    },
    settings: {
      neuUser: null,
      neuGrp: {
        id: 0,
        idElterngruppe: null,
        name: '',
        nameNachPrio: '',
        prio: [1, 0],
        rechte: [],
        idsNutzerparameter: [],
        standard: false,
        forcePWReset: false,
        sort: 0,
      },
      neuParam: {
        id: 0,
        idTyp: 1,
        name: '',
        selectoptions: [],
        sort: 0,
        ident: false,
        bedingungen: {
          c000005001: {
            sehen: {
              alle: true,
              nutzer: true,
              nurNutzer: [],
              nurGruppen: [],
              name: 'sehen',
              code: '000005001',
            },
            bearbeiten: {
              alle: true,
              nutzer: true,
              nurNutzer: [],
              nurGruppen: [],
              name: 'bearbeiten',
              code: '000005001',
            },
          }
        }
      },
    },
    benutzer: null,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    setNeuUser({ state, rootState }) {
      const neuUser = {
        id: 0,
        nutzername: '',
        name: '',
        vorname: '',
        nachname: '',
        email: '',
        rootPW: '',
        idsGruppen: [],
        rechteOhneGrp: [],
      };
      rootState.login.app.nutzergruppen.forEach((grp) => {
        if (grp.standard === true) {
          neuUser.idsGruppen.push(grp.id);
        }
      });
      state.settings.neuUser = neuUser;
    },
    tryGetData({ state }) {
      state.benutzer = null;
      this.dispatch('login/tryGetSettings').then(() => {
        this.dispatch('s000002/setNeuUser');
      });
      /* let local = {};
      if (localStorage.getItem('v000-002-001')) {
        local = JSON.parse(localStorage.getItem('v000-002-001'));
        state.benutzer = local.benutzer;
        this.dispatch('s000002/setNeuUser');
      }
      app.config.globalProperties.$weFetch({ path: '000-002/?001&getUser' }).then((res) => {
        if (res.data) {
          state.benutzer = res.data.benutzer;
          local.benutzer = res.data.benutzer;
          localStorage.setItem('v000-002-001', JSON.stringify(local));
          this.dispatch('s000002/setNeuUser');
        }
      }); */
    },
  },
  modules: {
  }
}
