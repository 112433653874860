<template>
  <div class="pa-4">
    <mainSelectColor
      v-model="color"
      label="Systemfarbe wählen"
      @update:modelValue="$weUpdatePrimaryColor(color)"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import mainSelectColor from '@/components/main/mainSelectColor.vue';

export default defineComponent({
  name: 'v000-001-Systemeinstellungen',

  components: {
    mainSelectColor,
  },
  data: () => ({
    color: '',
  }),
  computed: {
    currentTab() {
      return this.$router.currentRoute.value.params.id;
    },
  },
  watch: {
  },
  methods: {
  },
  created() {
    this.color = this.$vuetify.theme.current.colors['primary'];
  },
});
</script>
