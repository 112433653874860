<template>
  <div>
    <v-dialog
      v-model="dialogDelete"
      width="400px"
    >
      <v-card
        :style="`background-color:${$vuetify.theme.current.colors['background']};color:${$vuetify.theme.current.colors['background-text']};`"
      >
        <v-card-title
          :style="`background-color:${$vuetify.theme.current.colors['primary']};color:${$vuetify.theme.current.colors['primary-text']};`"
          class="ps-4"
        >
          {{ deleteObj.name }} löschen?
        </v-card-title>
        <v-card-text>
          {{ deleteText }}
          <p class="mt-2" v-if="deleteObj.idOriginal">
            <b>Wichtig:</b>
            "{{ deleteObj.name }}" kann nicht gelöscht werden.
            Daher werden in diesem Fall lediglich die Standardeinstellungen wieder hergestellt.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialogDelete = false" variant="text">abbrechen</v-btn>
          <v-spacer />
          <v-btn @click="tryDelObj(deleteObj.id)" variant="text" :color="$vuetify.theme.current.colors['error']">Löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="!$vuetify.display[breakpointRowStattDialog]"
      v-model="dialogEditObj"
      width="800px"
      fullscreen scrollable
      transition="dialog-bottom-transition"
    >
      <v-card
        :style="`background-color:${$vuetify.theme.current.colors['background']};color:${$vuetify.theme.current.colors['background-text']};`"
      >
        <v-card-actions
          :style="`background-color:${$vuetify.theme.current.colors['primary']};color:${$vuetify.theme.current.colors['primary-text']};`"
          class="ps-4"
        >
          {{ editObj?.name === '' ? typname+' hinzufügen' : editObj?.name }}
          <v-spacer />
          <v-btn icon
            :disabled="idsDontDelete.includes(editObj?.id)"
            @click="dialogDelObj(editObj)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn icon
            @click="closeDialogEditObj()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <mainEditUser
            v-if="typ === 'user'"
            v-model="editObj"
            @update:modelValue="trySaveObj()"
            @cancel="closeDialogEditObj()"
            :loadingSave="loadingSaveObj"
          />
          <c000002003EditGrp
            v-if="typ === 'grp'"
            v-model="editObj"
            @update:modelValue="trySaveObj()"
            @cancel="closeDialogEditObj()"
            :loadingSave="loadingSaveObj"
          />
          <c000002002EditParam
            v-if="typ === 'param'"
            v-model="editObj"
            @update:modelValue="trySaveObj()"
            @cancel="closeDialogEditObj()"
            :loadingSave="loadingSaveObj"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row style="max-width:1600px;" dense>
      <v-col>
        <div
          v-if="$vuetify.display[breakpointRowStattDialog]"
          align="left" class="pa-2"
        >
          <v-btn
            @click="edit(typNeu)"
            variant="text" :color="$vuetify.theme.current.colors['primary-darken-1']"
            v-if="editObj?.id !== 0"
          >
            <v-icon start>mdi-account-plus</v-icon>
            {{ typname }} hinzufügen
          </v-btn>
          <v-btn
            @click="editObj = undefined"
            variant="text" :color="$vuetify.theme.current.colors['primary-darken-1']"
            v-if="editObj?.id === 0"
          >
            <v-icon start>mdi-close</v-icon>
            {{ typname }} hinzufügen
          </v-btn>
        </div>
        <v-text-field
          type="search"
          clearable
          density="compact"
          label="suchen..."
          v-model="suchen"
        />
        <v-data-table
          :headers="dataTableHeaders"
          :items="dataTableItems"
          :style="`background-color:${$vuetify.theme.current.colors['background']};color:${$vuetify.theme.current.colors['background-text']};transform:translateY(-21px);`"
          :search="suchen"
        >
        <template v-slot:[`item.action`]="{ item }">
          <div align="right">
            <v-btn
              v-if="item.selectable.id !== editObj?.id"
              @click="edit(item.selectable)"
              variant="text" :color="$vuetify.theme.current.colors['primary-darken-1']"
              :disabled="item.selectable.id === editObj?.id"
            ><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn
              v-if="item.selectable.id === editObj?.id"
              @click="editObj = undefined"
              variant="text" :color="$vuetify.theme.current.colors['primary-darken-1']"
            ><v-icon>mdi-close</v-icon></v-btn>
          </div>
        </template>
        </v-data-table>
      </v-col>
      <v-col
        v-if="!editObj && $vuetify.display[breakpointRowStattDialog]"
        class="d-flex justify-center align-center"
      >
        {{ typnameKeine }} ausgewählt
      </v-col>
      <v-col
        v-if="editObj && $vuetify.display[breakpointRowStattDialog]"
        class="d-flex flex-column justify-start align-center mt-2 me-2"
      >
        <v-toolbar
          :style="`background-color:${$vuetify.theme.current.colors['primary']};color:${$vuetify.theme.current.colors['primary-text']};`"
        >
          <v-toolbar-title align="left">
            {{ editObj.name === '' ? typname+' hinzufügen' : editObj.name+' bearbeiten' }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon
            :disabled="idsDontDelete.includes(editObj?.id) || editObj.idOriginal === 0"
            @click="dialogDelObj(editObj)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn icon
            @click="editObj = undefined"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <mainEditUser
          v-if="typ === 'user'"
          v-model="editObj"
          @update:modelValue="trySaveObj()"
          @cancel="closeDialogEditObj()"
          :loadingSave="loadingSaveObj"
        />
        <c000002003EditGrp
          v-if="typ === 'grp'"
          v-model="editObj"
          @update:modelValue="trySaveObj()"
          @cancel="closeDialogEditObj()"
          :loadingSave="loadingSaveObj"
        />
        <c000002002EditParam
          v-if="typ === 'param'"
          v-model="editObj"
          @update:modelValue="trySaveObj()"
          @cancel="closeDialogEditObj()"
          :loadingSave="loadingSaveObj"
        />
      </v-col>
    </v-row>
    <v-btn
      v-if="!$vuetify.display[breakpointRowStattDialog]"
      icon
      :style="`position:fixed;right:0px;top:${this.$store.state.login.navigation.fixedContentPositionTop.mobile};transform:translate(-20px, calc(-100% - 20px));color:${$vuetify.theme.current.colors['primary-text']};`"
      :color="$vuetify.theme.current.colors['primary']"
      @click="edit(typNeu)"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import mainEditUser from '@/components/main/mainEditUser.vue';
import c000002003EditGrp from '@/components/000-002/c000-002-003-editGrp.vue';
import c000002002EditParam from '@/components/000-002/c000-002-002-editParam.vue';

export default {
  name: 'mainManageObj',

  components: {
    mainEditUser,
    c000002003EditGrp,
    c000002002EditParam,
  },
  props: {
    typ: {
      type: String,
      default: 'grp',
    },
    typname: {
      type: String,
      default: 'GruppeX',
    },
    typnameKeine: {
      type: String,
      default: 'Keine GruppeX',
    },
    typNeu: {
      type: Object,
      default() { return { id: 0, name: '' } },
    },
    delPath: {
      type: String,
      default: '000-002/?001&delGrp',
    },
    delSuccessDispatch: {
      type: String,
      default: '',
    },
    delSuccessCommit: {
      type: String,
      default: '',
    },
    savePath: {
      type: String,
      default: '000-002/?001&saveGrp',
    },
    saveSuccessDispatch: {
      type: String,
      default: '',
    },
    saveSuccessCommit: {
      type: String,
      default: '',
    },
    breakpointRowStattDialog: {
      type: String,
      default: 'mdAndUp',
    },
    dataTableItems: { // $store.state.login.app.nutzergruppen
      type: Array,
      default() {
        return [
          {
            id: 1,
            name: 'Test',
          },
        ]
      },
    },
    dataTableHeaders: {
      type: Array,
      default() {
        return [
          {
            title: 'Name',
            align: 'start',
            sortable: true,
            key: 'name',
          },
          {
            title: '',
            align: 'start',
            sortable: false,
            key: 'action',
          },
        ]
      },
    },
    deleteText: {
      type: String,
      default: 'Es werden sämtliche zusammenhängende Informationen und Daten unwiederruflich gelöscht.',
    },
    idsDontDelete: {
      type: Array,
      default() { return [] },
    },
  },
  data: () => ({
    fabShow: true,
    dialogEditObj: false,
    dialogDelete: false,
    deleteObjId: 0,
    loadingSaveObj: false,
    suchen: '',
    editObj: undefined,
  }),
  computed: {
    typCapitalizeFirstLetter() {
      const string = this.typ;
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
  },
  watch: {
    dialogEditObj(neu) {
      if (!neu) {
        this.closeDialogEditObj();
      }
    },
    editObj: {
      handler(neu) {
        if (neu && !this.$vuetify.display[this.breakpointRowStattDialog]) {
          this.dialogEditObj = true;
        }
      },
      deep: true,
    }
  },
  methods: {
    closeDialogEditObj() {
      this.editObj = undefined;
      this.dialogEditObj = false;
    },
    edit(obj) {
      this.editObj = JSON.parse(JSON.stringify(obj));
    },
    dialogDelObj(obj) {
      this.deleteObj = obj;
      this.dialogDelete = true;
    },
    tryDelObj(id) {
      this.dialogDelete = false;
      this.$weFetch({
        path: this.delPath,
        data: {
          id,
        },
      }).then((res) => {
        if (res.status === 'success') {
          this.closeDialogEditObj();
          if (this.delSuccessDispatch !== '') {
            this.$store.dispatch(this.delSuccessDispatch);
          }
          if (this.delSuccessCommit !== '') {
            this.$store.dispatch(this.delSuccessCommit);
          }
        }
      });
    },
    trySaveObj() {
      this.loadingSaveObj = true;
      this.$weFetch({
        path: this.savePath,
        data: {
          obj: JSON.stringify(this.editObj),
        },
      }).then((res) => {
        this.loadingSaveObj = false;
        if (res.status === 'success') {
          this.closeDialogEditObj();
          if (this.saveSuccessDispatch !== '') {
            this.$store.dispatch(this.saveSuccessDispatch);
          }
          if (this.saveSuccessCommit !== '') {
            this.$store.dispatch(this.saveSuccessCommit);
          }
          if (res.data[this.typ]) {
            this.editObj = res.data[this.typ];
          }
        } else {
          if (res.status === `error${this.typCapitalizeFirstLetter}nameInvalid`) {
            this.$store.commit('main/showSnackbar', {
              text: '<b>Fehler!</b> Der gewählte Name ist zu kurz',
            });
          } else if (res.status === `error${this.typCapitalizeFirstLetter}nameNotAvailable`) {
            this.$store.commit('main/showSnackbar', {
              text: '<b>Fehler!</b> Der gewählte Name ist bereits vergeben',
            });
          }
        }
      });
    }
  },
  created() {
  },
}
</script>
