<template>
  <div>
    <c001001001
      :query="$router.currentRoute.value.query"
      v-if="currentTab === '001' && $store.state.login.user.id"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import c001001001 from '@/components/001-001/c001-001-001.vue'

export default defineComponent({
  name: 'v001-001-Filecloud',

  components: {
    c001001001,
  },
  data: () => ({
  }),
  computed: {
    currentTab() {
      return this.$router.currentRoute.value.params.id;
    },
  },
  watch: {
    currentTab() {
      this.$store.dispatch('s001001/tryGetData');
    },
  },
  methods: {
  },
  created() {
    this.$store.dispatch('s001001/tryGetData');
  },
});
</script>
