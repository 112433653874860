import app from '@/main.js';

export default {
  namespaced: true,
  state: {
    settings: {
      neuVerzeichnis: undefined,
    },
    verzeichnisse: [],
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async tryGetData({ state }) {
      let local = {};
      if (localStorage.getItem('v001-001')) {
        local = JSON.parse(localStorage.getItem('v001-001'));
        state.verzeichnisse = local.verzeichnisse;
        state.settings = local.settings;
      }
      await app.config.globalProperties.$weFetch({ path: '001-001/?001&getData' }).then((res) => {
        if (res.data) {
          state.verzeichnisse = res.data.verzeichnisse;
          local.verzeichnisse = res.data.verzeichnisse;
          state.settings = res.data.settings;
          local.settings = res.data.settings;
          localStorage.setItem('v001-001', JSON.stringify(local));
        }
      });
    },
  },
  modules: {
  }
}
