<template>
  <!--
    <mainSelectColor
      v-model="color"
      label="Systemfarbe wählen"
      @update:modelValue="update()"
    />
  -->
  <v-menu :close-on-content-click="false" v-model="openMenu">
    <template v-slot:activator="{ props }">
      <span style="white-space:nowrap;">
        <v-btn
          v-bind="props"
          :size="size"
        >
          <v-icon start :color="value" size="x-large">mdi-water</v-icon>
          {{ label }}
        </v-btn>
      </span>
    </template>
    <v-list>
      <v-list-item>
        <v-color-picker
          hide-inputs show-swatches
          v-model="value"
          @update:modelValue="set()"
        ></v-color-picker>
      </v-list-item>
      <!-- <v-list-item>
        <v-row class="ma-1">
          <v-col>
            <v-btn block @click="openMenu = false" color="grey-lighten-2" size="small">Schließen</v-btn>
          </v-col>
          <v-col>
            <v-btn block @click="set();" size="small">Auswählen</v-btn>
          </v-col>
        </v-row>
      </v-list-item> -->
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'c000000001Dashboard',
  
  props: {
    label: {
      type: String,
      default: 'Farbe wählen',
    },
    size: {
      type: String,
      default: 'small',
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    openMenu: false,
    value: '',
  }),
  watch: {
    modelValue: {
      handler(neu) {
        this.setValue(neu);
      },
      deep: true,
    },
  },
  methods: {
    setValue(value) {
      this.value = value;
    },
    set() {
      this.$emit('update:modelValue', this.value);
    },
  },
  created() {
    this.setValue(this.modelValue);
  },
}
</script>
