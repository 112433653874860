<template>
  <!--
    <mainEditUser
      v-model="user"
      @update:modelValue="update()"
    />
  -->
  <div style="min-width:100%;">
    <v-tabs
      v-model="tabsCurrent"
      class="mb-4"
      v-if="[''].includes(view)"
    >
      <v-tab value="nutzerdaten">Nutzerdaten</v-tab>
      <v-tab value="rechte" v-if="[''].includes(view)">Rechte</v-tab>
    </v-tabs>
    <v-row dense width="100%">
      <v-col v-if="tabsCurrent === 'nutzerdaten'">
        <v-row  v-if="[''].includes(view)">
          <v-col cols="9">
            <v-text-field
              label="Nutzername"
              v-model="value.nutzername"
              @keyup="nutzernameFrei = false"
              :disabled="loadingCheckNutzername"
            />
          </v-col>
          <v-col cols="3" align="center">
            <v-btn
              v-if="!nutzernameFrei"
              class="mt-2" size="small"
              @click="checkNutzername()"
              :loading="loadingCheckNutzername"
            >
              <v-icon>mdi-help-box</v-icon>
            </v-btn>
            <v-btn
              v-if="nutzernameFrei"
              class="mt-2" size="small"
              color="success"
              style="pointer-events:none;"
            >
              <v-icon>mdi-marker-check</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row  v-if="[''].includes(view)">
          <v-col cols="12">
            <v-select
              multiple
              label="Nutzergruppenzuordnung"
              v-model="value.idsGruppen"
              :items="$store.state.login.app.nutzergruppen"
              item-title="nameNachPrio"
              item-value="id"
              @update:model-value="checkNutzerparameter()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="param in value.nutzerparameter"
            :key="param.id"
            :cols="param.cols"
          >
            <mainInputByType
              :label="param.name"
              :typ="param.idTyp"
              v-model="param.wert"
              :selectoptions="param.selectoptions"
              :readonly="Object.values(param.bedingungen).filter((o) => view === '' || (o.bearbeiten.code === view && o.bearbeiten.nutzer)).length <= 0"
            />
          </v-col>
        </v-row>
        <v-row  v-if="[''].includes(view)">
          <v-col cols="9">
            <v-text-field
              clearable
              label="Passwort vergeben"
              v-model="value.rootPW"
            />
          </v-col>
          <v-col cols="3" align="center">
            <v-btn
              class="mt-2" size="small"
              @click="createPW()"
            >
              <v-icon>mdi-autorenew</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="tabsCurrent === 'rechte'">
      <p align="left" class="pa-2" style="font-size:80%;">
        Die folgenden Rechte können unabhängig von den Rechten vergeben werden,
        die bereits aufgrund von Zugehörigkeiten zu Nutzergruppen gelten:
      </p>
      <mainSelectRechte v-model="value.rechteOhneGrp" />
    </div>
    <!-- <p :style="`font-size:80%;color:${$vuetify.theme.current.colors['error-darken-1']};`" class="pa-2">
      Bitte einen Nutzernamen angeben und über die Schaltfläche die Verfügbarkeit prüfen.
    </p> -->
    <div class="d-flex justify-space-around">
      <v-btn
        width="40%"
        v-if="!hideCancelButton"
        @click="close()"
        color="grey"
        :disabled="loadingSave"
      >Abbrechen</v-btn>
      <v-btn
        width="40%"
        @click="set()"
        :color="$vuetify.theme.current.colors['primary']"
        :style="`color:${$vuetify.theme.current.colors['primary-text']};`"
        :loading="loadingSave"
        v-if="
          [''].includes(view)
          || value.nutzerparameter.length > 0
        "
      >Speichern</v-btn>
      <p v-else-if="value.nutzerparameter.length <= 0" style="font-size:80%;" class="pa-2">
        Es wurden keine Nutzerparameter zugewiesen. Wenden Sie sich an den Administrator.
      </p>
    </div>
  </div>
</template>

<script>
import mainInputByType from '@/components/main/mainInputByType.vue';
import mainSelectRechte from '@/components/main/mainSelectRechte.vue';

export default {
  name: 'mainEditUser',
  
  components: {
    mainInputByType,
    mainSelectRechte,
  },
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    loadingSave: {
      type: Boolean,
      default: false,
    },
    hideCancelButton: {
      type: Boolean,
      default: false,
    },
    tabValue: {
      type: String,
      default: '',
    },
    view: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    loadingCheckNutzername: false,
    loadingResetPW: false,
    tabsCurrent: 'nutzerdaten',
    value: '',
    nutzernameFrei: false,
    dialogResetPW: false,
  }),
  watch: {
    modelValue: {
      handler(neu) {
        this.setValue(neu);
      },
      deep: true,
    },
  },
  methods: {
    setValue(value) {
      this.value = value;
    },
    set() {
      this.$emit('update:modelValue', this.value);
    },
    resetPW() {
      console.log(this.value);
      this.loadingResetPW = true;
      setTimeout(() => {
        this.loadingResetPW = false;
      }, 1500);
    },
    checkNutzername() {
      this.loadingCheckNutzername = true;
      this.$weFetch({
        path: 'vLogin/?checkUsername',
        data: { nn: this.value.nutzername, id: this.value.id },
        showNetworkAlert: true,
      })
        .then((res) => {
          this.loadingCheckNutzername = false;
          if (res.status === 'success') {
            if (res.data.usernameAvailable === 'success') {
              this.nutzernameFrei = true;
            } else if (res.data.usernameAvailable === 'errorUsernameNotAvailable') {
              this.$store.commit('main/showSnackbar', {
                text: '<b>Fehler!</b><br>Der gewählte Nutzername ist bereits vergeben.',
              });
            } else if (res.data.usernameAvailable === 'errorUsernameInvalid') {
              this.$store.commit('main/showSnackbar', {
                text: '<b>Fehler!</b><br>Der gewählte Nutzername ist zu kurz.',
              });
            }
          }
        });
    },
    createPW() {
      this.value.rootPW = this.$weCreateCode({ useLUndI: false });
    },
    close() {
      this.$emit('cancel');
    },
    checkNutzerparameter() {
      if (JSON.stringify(this.$store.state.login.app.nutzerparameter)) {
        const alleNutzerparameter = JSON.parse(JSON.stringify(this.$store.state.login.app.nutzerparameter));
        const nutzerparameter = [];
        alleNutzerparameter.forEach((param) => {
          this.$store.state.login.app.nutzergruppen.filter((o) => this.value.idsGruppen.includes(o.id) || [1, 2, 3].includes(o.id) || [1, 2, 3].includes(o.idOriginal)).forEach((grp) => {
            if (
              nutzerparameter.filter((o) => o.id === param.id).length <= 0
              && (
                grp.idsNutzerparameter.includes(param.id)
                || param.alle
              )
            ) {
              if (this.value.nutzerparameter) {
                if (
                  this.value.nutzerparameter.filter((o) => o.id === param.id).length > 0
                ) {
                  nutzerparameter.push(this.value.nutzerparameter.filter((o) => o.id === param.id)[0]);
                }
              }
              if (nutzerparameter.filter((o) => o.id === param.id).length <= 0) {
                nutzerparameter.push(param);
              }
            }
          });
        });
        this.value.nutzerparameter = nutzerparameter;
      }
    },
  },
  created() {
    this.setValue(this.modelValue);
    if (this.tabValue != '') {
      this.tabsCurrent = this.tabValue;
    }
    this.checkNutzerparameter();
  },
}
</script>
