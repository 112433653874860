<template> <!-- <mainSelectRechte v-model="input" /> und input: ['*'] -->
  <div align="left">
    <div class="d-flex flex-row justify-space-evenly">
      <v-btn
        @click="alleAktivieren()"
        size="small"
        :disabled="anzahlRechte <= Object.values(auswahlRechte).filter((o) => o === true).length"
      >Alle aktivieren</v-btn>
      <v-btn
        @click="auswahlRechte = {}"
        size="small"
        :disabled="Object.values(auswahlRechte).filter((o) => o === true).length <= 0"
      >Alle deaktivieren</v-btn>
    </div>
    <div
      v-for="seite in seiten"
      :key="seite.id"
      class="px-1"
    >
      <v-checkbox
        v-model="auswahlRechte[seite.id]"
        :disabled="seite.id !== '*' && auswahlRechte['*']"
        @click="changed(seite.id)"
      >
        <template v-slot:label>
          <div>
            <v-icon>{{ seite.icon }}</v-icon>
            {{ seite.titel }}
          </div>
        </template>
      </v-checkbox>
      <p
        class="ms-3"
        style="transform:translateY(-20px);font-size:80%;"
      >
        {{ seite.beschreibung }}
      </p>
      <!-- Rechte Ebene 1 -->
      <div
        v-if="
          auswahlRechte[seite.id]
          && Object.values(seite.rechte).filter((o) => o.ebene === 1).length > 0
        "
      >
        <div
          v-for="recht1 in Object.values(seite.rechte).filter((o) => o.ebene === 1)"
          :key="recht1.id"
          class="ps-4"
        >
          <v-checkbox
            v-if="recht1.id !== '001'"
            v-model="auswahlRechte[seite.id+'-'+recht1.id]"
            :disabled="
              auswahlRechte['*']
              || (auswahlRechte[seite.id+'-*'] && recht1.id !== '*')
              || recht1.id === '001'
            "
            @click="changed(seite.id+'-'+recht1.id)"
          >
            <template v-slot:label>
              <div>
                <v-icon>{{ recht1.icon }}</v-icon>
                {{ recht1.titel }}
                <!-- <span v-if="recht1.id === '001'">(Standard)</span> -->
              </div>
            </template>
          </v-checkbox>
          <div class="mt-2 mb-8" v-else-if="Object.values(seite.rechte).filter((o) => o.id.length === 7).length > 1">
            <v-icon>{{ recht1.icon }}</v-icon>
            {{ recht1.titel }}
            <!-- <span v-if="recht1.id === '001'">(Standard)</span> -->
          </div>
          <p
            v-if="Object.values(seite.rechte).filter((o) => o.id.length === 7).length > 1 || 1 === 1"
            class="ms-3"
            style="transform:translateY(-20px);font-size:80%;"
          >
            {{ recht1.beschreibung }}
          </p>
          <!-- Rechte Ebene 2 -->
          <div
            v-if="
              auswahlRechte[seite.id+'-'+recht1.id]
              && Object.values(seite.rechte).filter((o) => o.ebene === 2 && o.id.substring(0, 3) === recht1.id).length > 0
            "
          >
            <div
              v-for="recht2 in Object.values(seite.rechte).filter((o) => o.ebene === 2 && o.id.substring(0, 3) === recht1.id)"
              :key="recht2.id"
              class="ps-4"
            >
              <v-checkbox
                v-model="auswahlRechte[seite.id+'-'+recht2.id]"
                :disabled="
                  auswahlRechte['*']
                  || (auswahlRechte[seite.id+'-*'] && recht1.id !== '*')
                  || (auswahlRechte[seite.id+'-'+recht1.id+'-*'] && recht2.id !== '*')
                "
                @click="changed(seite.id+'-'+recht2.id)"
              >
                <template v-slot:label>
                  <div>
                    <v-icon>{{ recht2.icon }}</v-icon>
                    {{ recht2.titel }}
                  </div>
                </template>
              </v-checkbox>
              <p
                class="ms-3"
                style="transform:translateY(-20px);font-size:80%;"
              >
                {{ recht2.beschreibung }}
              </p>
              <!-- Rechte Ebene 3 -->
              <div
                v-if="auswahlRechte[seite.id+'-'+recht2.id] && Object.values(seite.rechte).filter((o) => o.ebene === 3 && o.id.substring(0, 7) === recht2.id).length > 0"
              >
                <div
                  v-for="recht3 in Object.values(seite.rechte).filter((o) => o.ebene === 3 && o.id.substring(0, 7) === recht2.id)"
                  :key="recht3.id"
                  class="ps-4"
                >
                  <v-checkbox
                    v-model="auswahlRechte[seite.id+'-'+recht3.id]"
                    :disabled="
                      auswahlRechte['*']
                      || (auswahlRechte[seite.id+'-*'] && recht1.id !== '*')
                      || (auswahlRechte[seite.id+'-'+recht1.id+'-*'] && recht2.id !== '*')
                      || (auswahlRechte[seite.id+'-'+recht1.id+'-'+recht2.id+'-*'] && recht3.id !== '*')
                    "
                    @click="changed(seite.id+'-'+recht3.id)"
                  >
                    <template v-slot:label>
                      <div>
                        <v-icon>{{ recht3.icon }}</v-icon>
                        {{ recht3.titel }}
                      </div>
                    </template>
                  </v-checkbox>
                  <p
                    class="ms-3"
                    style="transform:translateY(-20px);font-size:80%;"
                  >
                    {{ recht3.beschreibung }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-divider />
    </div>
  </div>
</template>

<script>
export default {
  name: 'mainSelectRechte',
  props: {
    seite: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Array,
      required: true,
      default() { return [] },
    }
  },
  data: () => ({
    auswahlRechte: {},
  }),
  computed: {
    seiten() {
      if (this.$store.state.login.app.seiten) {
        if (this.seite !== '') {
          return Object.values(this.$store.state.login.app.seiten).filter((o) => o.id.substring(0, 7) === this.seite);
        }
        return Object.values(this.$store.state.login.app.seiten);
      }
      return [];
    },
    anzahlRechte() {
      let i = 0;
      this.seiten.forEach((seite) => {
        if (!seite.id.includes('*')) {
          i += 1;
          Object.values(seite.rechte).forEach((recht) => {
            if (!recht.id.includes('*')) {
              i += 1;
            }
          });
        }
      });
      return i;
    },
  },
  watch: {
    modelValue: {
      handler(neu) {
        this.setAuswahlRechte(neu);
      },
      deep: true,
    },
  },
  methods: {
    setAuswahlRechte(array) {
      const auswahlRechte = {};
      array.forEach((a) => {
        auswahlRechte[a] = true;
      });
      this.auswahlRechte = auswahlRechte;
    },
    changed(ev) {
      if (!this.auswahlRechte[ev]) {
        this.auswahlRechte[ev] = true;
        if (ev.length === 7) {
          // Wenn das Recht auf eine Seite aktiviert wurde,
          // aktiviere ebenfalls den Zugriff auf die Standard-View dieser Seite
          this.auswahlRechte[ev+'-001'] = true;
        }
      } else {
        this.auswahlRechte[ev] = false;
        if (ev.length === 7) {
          // Wenn das Recht auf eine Seite deaktiviert wurde,
          // deaktiviere ebenfalls den Zugriff auf die Standard-View dieser Seite
          this.auswahlRechte[ev+'-001'] = false;
          // Entferne außerdem alle Rechte die dieser Seite untergeordnet sind:
          Object.keys(this.auswahlRechte).filter((o) => o.substring(0, 7) === ev).forEach((recht) => {
            this.auswahlRechte[recht] = false;
          });
        }
        if (ev.length === 11 && ev.substring(7, 11) === '-001') {
          // Wenn das Recht auf eine Standard-View einer Seite deaktiviert wurde,
          // deaktiviere ebenfalls den Zugriff auf die Seite
          this.auswahlRechte[ev.substring(0, 7)] = false;
          // Entferne außerdem alle Rechte die dieser Seite untergeordnet sind:
          Object.keys(this.auswahlRechte).filter((o) => o.substring(0, 7) === ev.substring(0, 7)).forEach((recht) => {
            this.auswahlRechte[recht] = false;
          });
        }
      }
      this.emitValue();
    },
    emitValue() {
      const auswahlRechte = [];
      Object.keys(this.auswahlRechte).forEach((recht) => {
        if (this.auswahlRechte[recht]) {
          auswahlRechte.push(recht);
        }
      });
      this.$emit('update:modelValue', auswahlRechte.sort());
    },
    alleAktivieren() {
      const rechte = {};
      this.seiten.forEach((seite) => {
        if (!seite.id.includes('*')) {
          rechte[seite.id] = true;
          Object.values(seite.rechte).forEach((recht) => {
            if (!recht.id.includes('*')) {
              rechte[`${seite.id}-${recht.id}`] = true;
            }
          });
        }
      });
      this.auswahlRechte = rechte;
    },
  },
  created() {
    setTimeout(() => {
      this.setAuswahlRechte(this.modelValue);
    }, 100);
  },
}
</script>
