import app from '@/main.js';

export default {
  namespaced: true,
  state: {
    loading: {
      tryGetSettings: false,
      tryRegister: false,
      tryLogin: false,
      tryLogout: false,
    },
    settings: {
      header: {
        height: 0, // 60
        heightUnit: 'vh', // vh
        logoWidth: 0, // in %
        logoMaxWidth: '500px', // 500px
        logoLink: '', // 'https://example.com' oder '' für keine Verlinkung
      },
      infos: [],
      realms: [],
      images: {},
    },
    user: {},
    users: [],
    navigation: {
      idSeite: null,
      seite: null,
      bottomNavItems: [],
      fixedContentPositionTop: {
        desktop: '48px',
        mobile: 'calc(100vh - 52px)',
      },
    },
    app: {
      realm: {
        id: 0,
        idname: '',
        name: '',
      }
    },
  },
  getters: {
  },
  mutations: {
    setNavigation(state, { idSeite }) {
      state.navigation.idSeite = idSeite;
      let seite = null;
      if (state.user.seiten) {
        seite = Object.values(state.user.seiten)
          .filter((o) => o.id === idSeite)[0];
      }
      state.navigation.seite = seite;
      let rechte = [];
      if (seite) {
        rechte = Object.values(seite.rechte).filter((o) => !o.id.includes('-') && !o.id.includes('*'));
        if (rechte.filter((o) => o.id === '001').length <= 0) {
          rechte.splice(0, 0, {
            id: '001',
            titel: seite.titel,
            icon: seite.icon,
          });
        }
      }
      state.navigation.bottomNavItems = rechte;
      let fixedContentPositionTop = {
        desktop: '48px',
        mobile: 'calc(100vh - 52px)',
      };
      if (rechte.length > 1) {
        fixedContentPositionTop = {
          desktop: '96px',
          mobile: 'calc(100vh - 100px)',
        };
      }
      if (!seite) {
        fixedContentPositionTop = {
          desktop: 'calc(100vh - 0px)',
          mobile: 'calc(100vh - 0px)',
        };
      }
      state.navigation.fixedContentPositionTop = fixedContentPositionTop;
    },
    setUserAndApp(state, { user, app }) {
      if (user) {
        if (JSON.stringify(state.user) !== JSON.stringify(user)) {
          state.user = user;
          if (
            !user.pwChanged
            && user.rechte?.includes('000-005-001-001')
          ) {
            this.commit('main/showSnackbar', {
              text: '<b>Passwort zurücksetzen empfohlen!</b><br /><br /><p align="justify">Es wird noch das Standard&shy;passwort verwendet. Aus Sicherheitsgründen wird daher empfohlen dieses durch ein eigenes sicheres Paswort zu ersetzen.</p><br /><a href="/000-005-001?setPW">Passwort jetzt ändern</a>',
              color: 'warning',
            });
          }
        }
      }
      if (app) {
        state.app = app;
      }
    },
    toggleActivationTab(state, { idSeite, idTab, value }) {
      state.user.seiten[idSeite].rechte[idTab].active = value;
    },
    setIdnameRealm(state, idNameRealm) {
      state.app.realm = {
        idname: idNameRealm,
      };
      if (localStorage.getItem('$_APP')) {
        const app = JSON.parse(localStorage.getItem('$_APP'));
        app.realm = {
          idname: idNameRealm,
        };
        localStorage.setItem('$_APP', JSON.stringify(app));
      }
    },
  },
  actions: {
    async tryGetSettings({state}, idRealm) {
      if (localStorage.getItem('vLogin-settings')) {
        const settings = JSON.parse(localStorage.getItem('vLogin-settings'));
        setTimeout(() => {
          state.settings = settings;
        }, 1);
      } else {
        state.loading.tryGetSettings = true;
      }
      const promiseBgImageLoaded = () => {
        return new Promise((resolve, reject) => {
          const backgroundImageElement = new Image();
          backgroundImageElement.src = '/img/vLogin/bg.jpg';
          backgroundImageElement.onload = () => {
            resolve();
          };
          backgroundImageElement.onerror = (error) => {
            reject(error);
          };
        });
      };
      if (!idRealm) {
        idRealm = state.app.realm?.idname;
      }
      await promiseBgImageLoaded().then(() => {
        app.config.globalProperties.$weFetch({
          path: 'vLogin/?getSettings',
          data: {
            idnameRealm: idRealm,
          },
        }).then((res) => {
          state.loading.tryGetSettings = false;
          if (res.data) {
            localStorage.setItem('vLogin-settings', JSON.stringify(res.data.settings));
            state.settings = res.data.settings;
          }
        });
      });
    },
    async tryRegister({state}, { nutzer }) {
      state.loading.tryRegister = true;
      await app.config.globalProperties.$weFetch({
        path: 'vLogin/?doRegister',
        data: {
          nutzer: JSON.stringify(nutzer),
        },
      }).then((e) => {
        state.loading.tryRegister = false;
        if (e.status === 'errorMailExists') {
          this.commit('main/showSnackbar', {
            text: '<b>Fehler!</b> Die angegebene E-Mail-Adresse wird bereits verwendet.',
          });
        } else if (e.status === 'errorNoRegActive') {
          this.commit('main/showSnackbar', {
            text: '<b>Achtung!</b> Die Registrierung ist zur Zeit leider nicht möglich. Bitte später erneut versuchen.',
          });
        } else if (e.status === 'successAccountToApply') {
          this.commit('main/showSnackbar', {
            text: '<b>Erfolg!</b> Es wurde ein neues Benutzerkonto eingerichtet: <b>'+e.data.nn+'</b><br />Bevor dieses verwendet werden kann, muss die registrierung von uns bestätigt werden. Hierüber wird per E-Mail informiert.',
            color: 'success',
          });
        } else if (e.status === 'successCreatedAccount') {
          this.commit('main/showSnackbar', {
            text: '<b>Erfolg!</b> Folgendes Benutzerkonto wurde eingerichtet und kann verwendet werden: <b>'+e.data.nn+'</b>',
            color: 'success',
          });
        }
      });
    },
    async tryLogin({state}, { nutzer }) {
      state.loading.tryLogin = true;
      app.config.globalProperties.$weFetch({
        path: 'vLogin/?doLogin',
        data: {
          idRealm: nutzer.realm.id,
          nn: nutzer.nutzername,
          pw: nutzer.passwort,
        },
        delay: 1000,
      }).then((e) => {
        state.loading.tryLogin = false;
        if (e.status === 'success') {
          /* this.commit('main/showSnackbar', {
            text: '<b>Login erfolgreich!</b>',
            color: 'success',
          }); */
        }
      });
    },
    async tryLogout({ state }, { router, hideAlert }) {
      this.commit('main/setOfflineModus', { val: false });
      state.loading.tryLogout = true;
      localStorage.clear();
      state.app.seiten = [];
      localStorage.setItem('$_APP', JSON.stringify(state.app));
      localStorage.setItem('vLogin-settings', JSON.stringify(state.settings));
      const $_USER = {};
      localStorage.setItem('$_USER', JSON.stringify($_USER));
      this.commit('login/setUserAndApp', { 
        user: $_USER,
      });
      app.config.globalProperties.$weFetch({
        path: 'vLogin/?getSettings&doLogout',
      }).then(() => {
        state.loading.tryLogout = false;
        router.push({ path: '/' });
        if (!hideAlert) {
          /* this.commit('main/showSnackbar', {
            text: '<b>Logout erfolgreich!</b>',
            color: 'success',
          }); */
        }
      });
    },
  },
  modules: {
  }
}
