<template>
  <v-app
    :style="`color:${$vuetify.theme.current.colors.text};background-color:${$vuetify.theme.current.colors.background};`"
  >
    <layoutNavigation
      v-if="$vuetify.display.mdAndUp && $store.state.login.user.seiten"
    />
    <layoutNavigation2
      v-else-if="$store.state.login.user.seiten"
    />
    <v-main>
      <!-- <v-progress-linear
        v-if="$store.state.main.offline"
        model-value="100"
        bg-color="error"
        :color="$vuetify.theme.current.colors['error']"
        :style="`position:fixed;top:${offlinePositionTop};`"
      ></v-progress-linear> -->
      <div
        v-if="$store.state.main.offline"
        :style="`background-color:${$vuetify.theme.current.colors['error']};color:${$vuetify.theme.current.colors['error-text']};position:fixed;z-index:10;font-size:80%;top:${offlinePositionTop};width:100%;`"
        class="pa-0 px-2" title="Offline"
      >
        <v-icon size="x-small" start>mdi-wifi-off</v-icon>
        Offline
      </div>
      <div
        v-if="$store.state.main.offline"
        style="position:relative;pointer-events:none;"
        class="ma-4"
      ></div>
      <v-progress-linear
        v-if="$store.state.main.activeFetchs.length > 0"
        indeterminate
        :color="$vuetify.theme.current.colors['primary']"
        bg-color="#ffffff"
        :style="`position:fixed;top:${loaderPositionTop};z-index:100;`"
      ></v-progress-linear>
      <router-view :style="`min-height:100vh;background-color:${$vuetify.theme.current.colors.background};`" />
      <div
        v-if="$store.state.login.app.urlImpressum"
        align="right"
        :style="`font-size:80%;min-height:10vh;background-color:${$vuetify.theme.current.colors.background};`"
        class="d-flex flex-column justify-end"
      >
        <div v-if="!$store.state.login.user.code">
          <a :href="$store.state.login.app.urlImpressum" :style="`color:${$vuetify.theme.current.colors.text};`" target="_blank">Impressum</a>
          |
          <a :href="$store.state.login.app.urlDatenschutz" :style="`color:${$vuetify.theme.current.colors.text};`" target="_blank">Datenschutz</a>
        </div>
      </div>
    </v-main>
    
    <v-snackbar
      v-model="snackbarShow"
      :location="snackbar.location"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      max-width="50vw"
      transition="v-slide-y-transition"
    >
      <p v-html="snackbar.text"></p>
      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="snackbarShow = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import layoutNavigation from '@/components/main/layoutNavigation.vue';
import layoutNavigation2 from '@/components/main/layoutNavigation2.vue';

export default {
  name: 'App',
  components: {
    layoutNavigation,
    layoutNavigation2,
  },
  data: () => ({
    snackbarShow: false,
  }),
  computed: {
    loginSettings() {
      return this.$store.state.login.settings;
    },
    snackbar() {
      return this.$store.state.main.snackbar;
    },
    bottomNavItems() {
      if (this.$store.state.login.user.seiten) {
        const idSeite = this.$router.currentRoute.value.fullPath.substring(1, 8);
        const seite = Object.values(this.$store.state.login.user.seiten)
          .filter((o) => o.id === idSeite)[0];
        if (seite) {
          const rechte = Object.values(seite.rechte).filter((o) => !o.id.includes('-') && !o.id.includes('*') && o.active);
          if (rechte.filter((o) => o.id === '001').length <= 0) {
            rechte.splice(0, 0, {
              id: '001',
              titel: seite.titel,
              icon: seite.icon,
            });
          }
          return rechte;
        }
      }
      return [];
    },
    offlinePositionTop() {
      if (this.$vuetify.display.mdAndUp) {
        if (this.bottomNavItems.length > 1) {
          return '96px';
        }
        return '48px';
      }
      return '0px';
    },
    loaderPositionTop() {
      if (
        this.$vuetify.display.mdAndUp
      ) {
        return this.$store.state.login.navigation.fixedContentPositionTop.desktop;
      }
      return this.$store.state.login.navigation.fixedContentPositionTop.mobile;
    },
  },
  watch: {
    loginSettings: {
      handler() {
        this.$weUpdatePrimaryColor();
      },
      deep: true,
    },
    snackbar: {
      handler() {
        this.snackbarShow = true;
      },
      deep: true,
    },
  },
  methods: {
    /* checkLoginSession() {
      if (localStorage.getItem('vLogin-session')) {
        const session = JSON.parse(localStorage.getItem('vLogin-session'));
        console.log(session);
      }
    }, */
  },
  created() {
    if (localStorage.getItem('$_USER')) {
      this.$store.commit('login/setUserAndApp', { 
        user: JSON.parse(localStorage.getItem('$_USER')),
      });
    }
    if (localStorage.getItem('$_APP')) {
      this.$store.commit('login/setUserAndApp', { 
        app: JSON.parse(localStorage.getItem('$_APP')),
      });
    }
    this.$weUpdatePrimaryColor();
  },
}
</script>
<style>
  html, body {
    /* overflow:hidden !important; */
    overscroll-behavior: none;
  }
  table th {
    background-color:rgba(0,0,0,0.05) !important;
  }
  table td {
    background-color:rgba(0,0,0,0) !important;
  }
  .v-list {
    background-color:rgba(255,255,255,0) !important;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  
  .fadeup-enter-active,
  .fadeup-leave-active {
    transform:translateY(-100px) !important;
    opacity:1;
    transition: all 0.5s ease;
  }

  .fadeup-enter-from,
  .fadeup-leave-to {
    transform:translateY(0px) !important;
    opacity: 1;
  }
</style>
