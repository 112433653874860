<template>
  <div>
    Kein Zugriff
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'vErrorKeinZugriff',

  components: {
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
  },
  created() {
  },
});
</script>
