<template>
    <!--
      <templateVMODEL
        v-model="value"
        @update:modelValue="update()"
      />
    -->
    <v-select
      label="Parametertyp"
      v-model="value.idTyp"
      :items="$store.state.login.app.nutzerparameterTypen"
      item-title="name"
      item-value="id"
      @change="set()"
      :disabled="value.id !== 0"
    />
    <p class="mx-4" align="left" style="transform:translateY(-20px);font-size:80%;">
      <b>Achtung:</b> Der Typ kann nur einmal bei der Erstellung eines Parameters ausgewählt werden.
      Das Ändern des Typs ist nach der Erstellung nicht mehr möglich.
    </p>
    <div v-if="[9, 10].includes(value.idTyp)">
      <v-row
        dense
        v-for="(option, idx) in value.selectoptions"
        :key="idx"
      >
        <v-col cols="8">
          <v-text-field
            label="Wert"
            v-model="option.wert"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            label="#"
            v-model="option.sort"
          />
        </v-col>
        <v-col cols="2" align="center">
          <v-btn size="small" class="mt-2" @click="delSelectoption(idx)"><v-icon>mdi-minus-box</v-icon></v-btn>
        </v-col>
      </v-row>
      <div align="center" class="mb-6">
        <v-btn @click="addSelectoption()"><v-icon start>mdi-plus-box</v-icon>Option</v-btn>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'mainSelectParameterTyp',
    
    props: {
      modelValue: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data: () => ({
      value: '',
    }),
    watch: {
      modelValue: {
        handler(neu) {
          this.setValue(neu);
        },
        deep: true,
      },
    },
    methods: {
      setValue(value) {
        this.value = value;
      },
      set() {
        this.$emit('update:modelValue', this.value);
      },
      addSelectoption() {
        let sort = 0;
        this.value.selectoptions.forEach((o) => {
          if (o.sort > sort) {
            sort = o.sort;
          }
        });
        sort = parseInt(sort, 10) + 1;
        this.value.selectoptions.push({
          id: 0,
          wert: '',
          sort,
        });
      },
      delSelectoption(idx) {
        this.value.selectoptions.splice(idx, 1);
      },
    },
    created() {
      this.setValue(this.modelValue);
    },
  }
  </script>
  