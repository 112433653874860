<template>
  <div>
    <div v-html="$sanitize(`<div>${html}</div>`)"></div>
  </div>
</template>

<script>
export default {
  name: 'mainSelectRechte',
  props: {
    html: {
      type: String,
      default: '',
    },
  },
  data: () => ({
  }),
}
</script>
