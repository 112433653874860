import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import './registerServiceWorker'

import myPlugin from "./plugins/wePlugin";
import Vue3Sanitize from "vue-3-sanitize";

loadFonts()

const overridenOptions = {
  allowedTags: ['img','b','br','div','a']
};

const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(myPlugin)
  .use(Vue3Sanitize, overridenOptions)

export default app;
  
app.mount('#app')