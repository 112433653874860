import { createRouter, createWebHistory } from 'vue-router'
import app from '@/main.js';
import Store from '@/store';
import vLogin from '../views/vLogin.vue'
import vErrorKeinZugriff from '../views/vErrorKeinZugriff.vue'
import v000000 from '../views/v000-000.vue'
import v000001 from '../views/v000-001.vue'
import v000002 from '../views/v000-002.vue'
import v000005 from '../views/v000-005.vue'
import v001001 from '../views/v001-001.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: vLogin,
  },
  {
    path: '/000-000-:id',
    name: 'Dashboard',
    component: v000000,
  },
  {
    path: '/000-001-:id',
    name: 'Systemeinstellungen',
    component: v000001,
  },
  {
    path: '/000-002-:id',
    name: 'Benutzerverwaltung',
    component: v000002,
  },
  {
    path: '/000-005-:id',
    name: 'MeinProfil',
    component: v000005,
  },
  {
    path: '/001-001-:id',
    name: 'Filecloud',
    component: v001001,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'KeinZugriff',
    component: vErrorKeinZugriff,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  let local = {};
  if (localStorage.getItem('$_LOCAL')) {
    local = JSON.parse(localStorage.getItem('$_LOCAL'));
  }
  let result = true;
  
  let $_USER = Store.state.login.user;
  /* if (!$_USER && localStorage.getItem('$_USER')) {
    $_USER = JSON.parse(localStorage.getItem('$_USER'));
  } */

  if (to.fullPath !== '/') {
    if ($_USER.code) {
      // Wenn NICHT Login aufgerufen wird, und man eingeloggt ist,
      // prüfe ob der Zugriff erlaubt ist:
      if (Object.values($_USER.seiten)?.filter((o) => '/'+o.id === to.fullPath.substring(0, 8)).length <= 0) {
        // Wenn der Zugriff nicht erlaubt ist,
        // prüfe ob andere Seiten erlaubt sind:
        if (Object.values($_USER.seiten).length > 0) {
          // Wenn andere Seiten erlaubt sind,
          // leite zur ersten erlaubten Seite weiter:
          result = { path: '/'+Object.values($_USER.seiten)[0].id+'-001' };
        } else {
          // sonst leite zur Login-Seite weiter:
          if (from.fullPath === '/') {
            // Wenn bereits auf der Login-Seite,
            // dann beende Weiterleitung
            result = false;
          } else {
            // sonst leite zur Login-Seite weiter:
            result = { path: '/' };
          }
        }
      } else {
        // speichere diese Seite als zuletzt aufgerufene Seite:
        local.lastView = to.fullPath;
        localStorage.setItem('$_LOCAL', JSON.stringify(local));
      }
      // speichere diese Seite als zuletzt aufgerufene Seite:
      local.lastView = to.fullPath;
      localStorage.setItem('$_LOCAL', JSON.stringify(local));
    } else if (
      to.path === '/'
      && Object.keys(to.query).length >= 1
      && !$_USER.code
    ) {
      // Wenn Login aufgerufen wurde und Query-Parameter übergeben wurden
      Object.keys(to.query).forEach((key) => {
        console.log(key, to.query[key])
        if (
          key !== 'usr'
          && !to.query[key]
        ) {
          Store.commit('login/setIdnameRealm', Object.keys(to.query)[0]);
        }
      });
    } else {
      // Wenn NICHT Login aufgerufen wird obwohl man nicht eingeloggt ist,
      // leite zur Login-Seite weiter:
      result = { path: '/' };
    }
  } else if (
    to.fullPath === '/'
    && $_USER.code
  ) {
    // Wenn Login aufgerufen wird obwohl man bereits eingeloggt ist,
    if (local.lastView === from.fullPath) {
      // Wenn man sich bereits auf der zuletzt aufgerufene Seite befindet,
      // leite einfach nicht weiter:
      result = false;
    } else {
      // sonst leite auf die zuletzt aufgerufene Seite weiter:
      result = { path: local.lastView };
    }
  }
  if (from.path.substring(1, 8) !== to.path.substring(1, 8)) {
    app.config.globalProperties.$weFetch({});
  }
  window.scrollTo(0, 0);
  if (result) {
    const idSeite = to.path.substring(1, 8);
    const AppName = Store.state.login.app.titel;
    let seite = {};
    if ($_USER.seiten) {
      seite = $_USER.seiten[idSeite];
    }
    let title = AppName;
    if (seite) {
      Store.commit('login/setNavigation', { idSeite: seite.id });
      if (seite.titel) {
        // title += ` - ${seite.titel}`;
        title = AppName+' - '+seite.titel;
      }
    }
    document.title = title;
  }
  return result;
})

export default router
