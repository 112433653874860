import { createStore } from 'vuex'
import main from '@/store/modules/main'
import login from '@/store/modules/login'
import s000002 from '@/store/modules/s000002'
import s001001 from '@/store/modules/s001001'

export default createStore({
  modules: {
    main,
    login,
    s000002,
    s001001,
  }
})
