<template>
    <!--
      <c001001001EditVerz
        v-model="value"
        @update:modelValue="update()"
      />
    -->
    <v-dialog
      v-model="dialogDelete"
      width="400px"
    >
      <v-card
        :style="`background-color:${$vuetify.theme.current.colors['background']};color:${$vuetify.theme.current.colors['background-text']};`"
      >
        <v-card-title
          :style="`background-color:${$vuetify.theme.current.colors['primary']};color:${$vuetify.theme.current.colors['primary-text']};`"
          class="ps-4"
        >
          {{ value?.name }} löschen?
        </v-card-title>
        <v-card-text>
          Sämtliche Dateien und Verzeichnisse innerhalb dieses Verzeichnisses gehen dabei verloren.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialogDelete = false" variant="text">abbrechen</v-btn>
          <v-spacer />
          <v-btn variant="text" :color="$vuetify.theme.current.colors['error']" @click="tryDel()" :loading="loadingDel">Löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogOpen"
      :width="$vuetify.display.mdAndUp ? '800px' : ''"
      :fullscreen="!$vuetify.display.mdAndUp" scrollable
      transition="dialog-bottom-transition"
    >
      <v-card
        :style="`background-color:${$vuetify.theme.current.colors['background']};color:${$vuetify.theme.current.colors['background-text']};`"
      >
        <v-card-actions
          :style="`background-color:${$vuetify.theme.current.colors['primary']};color:${$vuetify.theme.current.colors['primary-text']};`"
          class="ps-4"
        >
          {{ value?.name === '' ? 'Verzeichnis hinzufügen' : value?.name }}
          <v-spacer />
          <v-btn icon
            :disabled="value?.id <= 0"
            @click="dialogDelete = true"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn icon
            @click="cancel()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text style="min-height:400px;">
          <v-form
            v-if="value"
            v-model="valid"
          >
            <v-text-field
              label="Verzeichnisname"
              v-model="value.name"
              :rules="[
                $store.state.main.inputValidationRules.filename,
              ]"
            />
            <mainSelectBedingungen
              titel="Berechtigungen"
              beschreibung="Wer darf Zugriff auf dieses Verzeichnis und die enthaltenen Dateien erhalten, und diese ggf. ändern?"
              v-model="value.bedingungen.c001001001"
            />
          </v-form>
          <!-- <div class="d-flex justify-space-around">
            <div style="width:40%;"><v-btn block
              @click="cancel()"
              color="grey"
              :disabled="loadingSave"
            >Abbrechen</v-btn></div>
            <div style="width:40%;"><v-btn block
              :color="$vuetify.theme.current.colors['primary']"
              :style="`color:${$vuetify.theme.current.colors['primary-text']};`"
              :loading="loadingSave"
              :disabled="!valid"
              @click="trySave()"
            >Speichern</v-btn></div>
          </div> -->
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="cancel()"
            color="grey"
            :disabled="loadingSave"
          >Abbrechen</v-btn>
          <v-spacer />
          <v-btn
            :style="`color:${$vuetify.theme.current.colors['primary']};`"
            :loading="loadingSave"
            :disabled="!valid"
            @click="trySave()"
          >Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import mainSelectBedingungen from '@/components/main/mainSelectBedingungen.vue';

  export default {
    name: 'c001001001EditVerz',
    
    components: {
      mainSelectBedingungen,
    },
    props: {
      modelValue: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data: () => ({
      dialogOpen: false,
      dialogDelete: false,
      loadingSave: false,
      loadingDel: false,
      value: '',
      valid: null,
    }),
    watch: {
      modelValue: {
        handler(neu, alt) {
          const val = JSON.parse(JSON.stringify(neu));
          if (
            val.id === 0
            && JSON.stringify(neu) !== JSON.stringify(alt)
            // && !val.bedingungen.c001001001.Einstellungen_verwalten.alle
            && !val.bedingungen.c001001001.Einstellungen_verwalten.nurNutzer.includes(this.$store.state.login.user.id)
          ) {
            val.bedingungen.c001001001.Einstellungen_verwalten.nurNutzer.push(this.$store.state.login.user.id);
            val.bedingungen.c001001001.Einstellungen_verwalten.nutzer = true;
          }
          this.setValue(val);
        },
        deep: true,
      },
    },
    methods: {
      setValue(value) {
        this.value = value;
        this.checkDialogOpenCloseByValue();
      },
      checkDialogOpenCloseByValue() {
        if (this.value?.id >= 0) {
          this.dialogOpen = true;
        } else {
          this.dialogOpen = false;
        }
      },
      cancel() {
        this.value = undefined;
        this.checkDialogOpenCloseByValue();
      },
      set() {
        this.$emit('update:modelValue', this.value);
      },
      trySave() {
        this.loadingSave = true;
        this.$weFetch({
          path: '001-001/?001&saveVerz',
          data: {
            obj: JSON.stringify(this.value),
          },
        }).then((/* res */) => {
          this.$store.dispatch('s001001/tryGetData').then(() => {
            this.loadingSave = false;
            /* if (res.data?.idNeuVerz) {
              this.$router.push(`/001-001-001?v=${res.data.idNeuVerz}`);
            } */
            this.cancel();
          });
        });
      },
      tryDel() {
        this.loadingDel = true;
        this.$weFetch({
          path: '001-001/?001&delVerz',
          data: {
            id: this.value.id,
          },
        }).then(() => {
          this.$store.dispatch('s001001/tryGetData').then(() => {
            this.loadingDel = false;
            this.dialogDelete = false;
            this.cancel();
          });
        });
      },
    },
    created() {
      this.setValue(this.modelValue);
    },
  }
  </script>
  