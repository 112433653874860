<template>
  <!--
    <003EditGrp
      v-model="grp"
      @update:modelValue="update()"
    />
  -->
  <div style="min-width:100%;">
    <v-tabs
      v-model="tabsDatenRechte"
      class="mb-4"
    >
      <v-tab value="nutzerdaten">Gruppendaten</v-tab>
      <v-tab
        value="rechte"
        :disabled="value.id === 1"
      >Rechte</v-tab>
    </v-tabs>
    <p
      v-if="value.id === 1"
      style="font-size:80%;"
      class="pa-2"
    >
      Der Systemadministrator besitzt grundsätzlich alle Rechte
    </p>
    <v-row dense width="100%">
      <v-col v-if="tabsDatenRechte === 'nutzerdaten'">
        <v-row dense>
          <v-col cols="9">
            <v-text-field
              label="Gruppenname"
              v-model="value.name"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="#"
              v-model="value.sort"
            />
          </v-col>
          <v-col cols="12"
            v-if="$store.state.login.app.nutzergruppen.filter((o) => !o.idElterngruppe && o.id !== value.id).length > 0"
          >
            <v-select
              clearable
              label="Bestehender Gruppe unterordnen"
              v-model="value.idElterngruppe"
              :items="$store.state.login.app.nutzergruppen.filter((o) => !o.idElterngruppe && o.id !== value.id)"
              item-title="nameNachPrio"
              item-value="id"
            />
          </v-col>
          <v-col cols="12"
            v-if="$store.state.login.app.nutzerparameter.filter((o) => !o.alle).length > 0"
          >
            <v-select
              multiple
              label="Nutzerparameter"
              v-model="value.idsNutzerparameter"
              :items="$store.state.login.app.nutzerparameter.filter((o) => !o.alle)"
              item-title="name"
              item-value="id"
            />
          </v-col>
          <v-col cols="12" align="left">
            <v-checkbox
              label="Standardgruppe"
              v-model="value.standard"
            />
            <p class="mx-4" style="transform:translateY(-20px);font-size:80%;">
              Wird jedem neu erstellen Benutzer zugewiesen
            </p>
          </v-col>
          <v-col cols="12" align="left">
            <v-checkbox
              label="Passwort ersetzen erzwingen"
              v-model="value.forcePWReset"
            />
            <p class="mx-4" style="transform:translateY(-20px);font-size:80%;">
              Nutzer werden aufgefordert das Standardpasswort durch ein eigenes Passwort zu ersetzen
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="tabsDatenRechte === 'rechte'">
      <p align="left" class="pa-2" style="font-size:80%;">
        Die folgenden Rechte können unabhängig von den Rechten vergeben werden,
        die bereits aufgrund von Zugehörigkeiten zu Nutzergruppen gelten:
      </p>
      <mainSelectRechte v-model="value.rechte" />
    </div>
    <div class="d-flex justify-space-around">
      <div style="width:40%;"><v-btn block
        @click="$emit('cancel')"
        color="grey"
        :disabled="loadingSave"
      >Abbrechen</v-btn></div>
      <div style="width:40%;"><v-btn block
        @click="set()"
        :color="$vuetify.theme.current.colors['primary']"
        :style="`color:${$vuetify.theme.current.colors['primary-text']};`"
        :loading="loadingSave"
      >Speichern</v-btn></div>
    </div>
  </div>
</template>

<script>
import mainSelectRechte from '@/components/main/mainSelectRechte.vue';

export default {
  name: 'c000002003EditGrp',
  
  components: {
    mainSelectRechte,
  },
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    loadingSave: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tabsDatenRechte: undefined,
    value: '',
  }),
  watch: {
    modelValue: {
      handler(neu) {
        this.setValue(neu);
      },
      deep: true,
    },
  },
  methods: {
    setValue(value) {
      if (value.id === 0) {
        let i = 0;
        this.$store.state.login.app.nutzergruppen.filter((o) => !o.idElterngruppe).forEach((g) => {
          if (g.sort > i) {
            i = g.sort;
          }
        });
        i += 1;
        value.sort = i;
      }
      value.idsNutzerparameter?.forEach((p, idx) => {
        if (this.$store.state.login.app.nutzerparameter.filter((o) => o.id === p).length <= 0) {
          value.idsNutzerparameter.splice(idx, 1);
        }
      });
      this.value = value;
    },
    set() {
      this.$emit('update:modelValue', this.value);
    },
  },
  created() {
    this.setValue(this.modelValue);
  },
}
</script>
