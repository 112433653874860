export default {
  namespaced: true,
  state: {
    inputValidationRules: {
      // Inhalt darf nicht leer sein:
      required: (value) => !!value || 'Bitte angeben.',
      // Inhalt darf nicht lang sein:
      tinytext: (value) => value.length <= 25 || 'Maximal 25 Zeichen sind erlaubt',
      // Inhalt darf kaum Sonderzeichen enthalten:
      nutzername: (value) => {
        const pattern = /^[A-Za-z0-9\-_.äöüÄÖÜß]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf kaum Sonderzeichen enthalten:
      name: (value) => {
        const pattern = /^[A-Za-z0-9 :|\-_.,äöüÄÖÜß]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf auch keine Umlaute oder Leerzeichen enthalten:
      nameLess: (value) => {
        const pattern = /^[A-Za-z0-9\-_]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf mehr Sonderzeichen enthalten:
      nameMore: (value) => {
        const pattern = /^[A-Za-z0-9 :|+,()\-_.äöüÄÖÜß]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      filename: (value) => {
        const pattern = /^[A-Za-z0-9 \-_+.,;()[\]{}#=&%$§!~äöüÄÖÜß]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf alle Sonderzeichen außer " enthalten:
      pw: (value) => {
        const pattern = /^[A-Za-z0-9 \-_.äöüÄÖÜß!$%^&*() +|~=`\\#{}[\]:;<>²³°'´?,/]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt muss mindestens 8 Zeichen enthalten:
      minPW: (value) => value.length >= 8 || 'Mindestens 8 Zeichen angeben',
      // Inhalt darf nur ein deutsches Datum enthalten:
      datum: (value) => {
        const pattern = /(^$)|(\b[0-9]{1,2}[.][0-9]{1,2}[.][0-9]{4}\b)/;
        return pattern.test(value) || 'Bitte im folgenden Format angeben: TT.MM.YYYY';
      },
      // Inhalt darf nur Ziffern enthalten:
      nummer: (value) => {
        const pattern = /^s*|[0-9]*/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf nur Koordinaten enthalten:
      koordinaten: (value) => {
        const pattern = /^s*|[0-9 -]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf nur Sonderzeichen aus Telefonnummern enthalten:
      telefon: (value) => {
        const pattern = /^[0-9 \-/+]*$/;
        return pattern.test(value) || !value || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf alle Sonderzeichen enthalten:
      text: (value) => {
        const pattern = /^[A-Za-z0-9 \-_.äöüÄÖÜß!$%^&*() +|~=`\\#{}[\]:;<>"'?,/]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf nur Sonderzeichen für URLs enthalten:
      url: (value) => {
        const pattern = /^[A-Za-z0-9/\-?=&.]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt muss eine gültige E-Mail Adresse sein:
      email: (value) => {
        const pattern = /((^$)|(^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$))/;
        return pattern.test(value) || !value || 'Ungültige E-Mail-Adresse';
      },
    },
    fetchMaxTimeout: 8000, // in ms
    offline: false, // Wenn aktiviert, wird angezeigt, dass keine Netzwerkverbindung besteht es wird in Zukunft generell kein Netzwerk mehr versucht zu nutzen
    activeFetchs: [],
    snackbar: {},
  },
  getters: {
  },
  mutations: {
    setOfflineModus(state, { val, toggle }) {
      if (toggle) {
        state.offline = !state.offline;
      } else {
        state.offline = val;
      }
    },
    startNewFetch(state, { id }) {
      const date = new Date();
      state.activeFetchs.forEach(({ id }, idx) => {
        if (id < (date.getTime() - state.fetchMaxTimeout)) {
          state.activeFetchs.splice(idx, 1);
        }
      });
      state.activeFetchs.push({ id });
    },
    endFetch(state, { id }) {
      if (state.activeFetchs.filter((o) => o.id === id).length > 0) {
        state.activeFetchs.filter((o) => o.id <= id).forEach((i, idx) => {
          state.activeFetchs.splice(idx, 1);
        });
      }
      if (state.offline) {
        state.activeFetchs = [];
      }
      state.activeFetchs = [];
    },
    showSnackbar(state, { text, location, timeout, color }) {
      state.snackbar.text = '';
      setTimeout(() => {
        state.snackbar.text = text || '';
        state.snackbar.location = location || 'bottom center';
        state.snackbar.timeout = timeout || 8000;
        state.snackbar.color = color || 'gry-darken-3';
      }, 100);
    },
  },
  actions: {
  },
  modules: {
  }
}
