<template>
  <div>
    <v-container fluid v-if="currentTab === '001'">
      <c000000001 />
    </v-container>
    <v-container fluid v-if="currentTab === 'set'">
      Einstellungen
    </v-container>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import c000000001 from '@/components/000-000/c000-000-001.vue';

export default defineComponent({
  name: 'v000-000-Dashboard',

  components: {
    c000000001,
  },
  data: () => ({
  }),
  computed: {
    currentTab() {
      return this.$router.currentRoute.value.params.id;
    },
  },
  watch: {
  },
  methods: {
  },
  created() {
  },
});
</script>
