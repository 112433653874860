<template>
  <div>
    Noch kein Konto?
    <v-btn
      variant="text"
      size="small"
      style="transform:translateY(-2px);"
      @click="dialogShow = true"
    >
      Jetzt Registrieren
    </v-btn>
    <v-dialog
      v-model="dialogShow"
      :fullscreen="$vuetify.display.mdAndDown"
      width="800px"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title :style="`background-color:${$vuetify.theme.current.colors['primary']};color:${$vuetify.theme.current.colors['primary-text']};`">
          Registrieren
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="validForm">
              <p
                v-html="$store.state.login.settings.reg.textIntroduction"
                class="mb-6"
              ></p>
              <v-text-field
                label="Vorname"
                v-model="regdataVorname"
                :rules="[
                  $store.state.main.inputValidationRules.required,
                ]"
                :diabled="$store.state.login.loading.tryRegister"
              />
              <v-text-field
                label="Nachname"
                v-model="regdataNachname"
                :rules="[
                  $store.state.main.inputValidationRules.required,
                ]"
                :diabled="$store.state.login.loading.tryRegister"
              />
              <v-text-field
                label="E-Mail Adresse"
                v-model="regdataEmail"
                :rules="[
                  $store.state.main.inputValidationRules.required,
                  $store.state.main.inputValidationRules.email,
                ]"
                :diabled="$store.state.login.loading.tryRegister"
              />
              <v-text-field
                type="password"
                label="Passwort"
                v-model="regdataPasswort"
                :rules="[
                  $store.state.main.inputValidationRules.required,
                ]"
                @update:model-value="regdataPasswort2 = ''"
                :diabled="$store.state.login.loading.tryRegister"
              />
              <v-text-field
                type="password"
                label="Passwort wiederholen"
                v-model="regdataPasswort2"
                :rules="[
                  $store.state.main.inputValidationRules.required,
                  (value) => value === regdataPasswort ? true : 'Stimmt nicht mit der ersten Eingabe überein',
                ]"
                :diabled="$store.state.login.loading.tryRegister"
              />
              <v-textarea
                v-if="
                  $store.state.login.settings.reg.apply === '1'
                  && $store.state.login.settings.reg.applyTextareaLabel !== ''
                "
                :label="$store.state.login.settings.reg.applyTextareaLabel"
                v-model="regdataTextarea"
                :diabled="$store.state.login.loading.tryRegister"
              />
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            variant="text"
            size="small"
            @click="dialogShow = false"
          >
            Abbrechen
          </v-btn>
          <v-spacer />
          <v-btn
            variant="text"
            size="small"
            @click="
              $store.dispatch('login/tryRegister', { nutzer: {
                idRealm,
                vorname: regdataVorname,
                nachname: regdataNachname,
                email: regdataEmail,
                pw: regdataPasswort,
                textarea: regdataTextarea,
              } }).then(() => {
                regdataPasswort = '';
                regdataPasswort2 = '';
              });
            "
            :style="`color:${$vuetify.theme.current.colors['primary']};`"
            :disabled="!validForm"
            :loading="$store.state.login.loading.tryRegister"
          >
            Registrieren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
  export default {
    name: 'vLoginRegistrieren',
    
    props: {
      idRealm: {
        type: Number,
        default: 0,
      },
    },
    data: () => ({
      validForm: null,
      dialogShow: false,
      regdataVorname: '',
      regdataNachname: '',
      regdataEmail: '',
      regdataPasswort: '',
      regdataPasswort2: '',
      regdataTextarea: '',
    }),
    watch: {
    },
    methods: {
    },
    created() {
    },
  }
  </script>
  