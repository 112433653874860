<template>
  <v-container>
    Dashboard
  </v-container>
</template>

<script>

export default {
  name: 'c000000001Dashboard',

  components: {
  },
  data: () => ({
  }),
  methods: {
  },
  created() {
  },
}
</script>
