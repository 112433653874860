<template>
    <!--
      <mainSelectBedingungen
        titel="asd"
        beschreibung="asdf"
        v-model="value"
        @update:modelValue="update()"
      />
    -->
    <p class="mx-2 mb-1">{{ titel }}</p>
    <p class="mx-4" style="font-size:80%;">
      {{ beschreibung }}
    </p>
    <div
      class="mx-4"
      v-for="bed in value"
      :key="bed.code"
    >
      <v-checkbox
        :label="`Alle dürfen ${bed.name}`"
        v-model="bed.alle"
      />
      <v-combobox
        v-if="!bed.alle"
        multiple clearable
        v-model="bed.nurGruppen"
        :items="$store.state.login.app.nutzergruppen"
        item-title="name"
        item-value="id"
        :label="`Welche Gruppen dürfen ${bed.name}?`"
      />
      <v-combobox
        v-if="!bed.alle"
        multiple clearable
        v-model="bed.nurNutzer"
        :items="$store.state.login.app.users"
        item-title="name"
        item-value="id"
        :label="`Wer darf ${bed.name}?`"
      />
    </div>
    <!-- <v-btn @click="set()">set</v-btn> -->
  </template>
  
  <script>
  export default {
    name: 'mainSelectBedingungen',
    
    props: {
      modelValue: {
        type: Object,
        default() {
          return {};
        },
      },
      titel: {
        type: String,
        default: 'Bedingung X',
      },
      beschreibung: {
        type: String,
        default: 'Lorem ipsum dolor sit amet, consetetur est.',
      },
    },
    data: () => ({
      value: '',
    }),
    watch: {
      modelValue: {
        handler(neu) {
          this.setValue(neu);
        },
        deep: true,
      },
    },
    methods: {
      setValue(value) {
        this.value = value;
      },
      set() {
        this.$emit('update:modelValue', this.value);
      },
    },
    created() {
      this.setValue(this.modelValue);
    },
  }
  </script>
  