<template>
  <v-container fluid class="pa-0">
    <c001001001EditVerz v-model="editVerz" />
    <c001001001EditFile v-model="editFile" />
    <c001001001Upload
      :idVerz="idVerz"
      :eventOpenDialog="eventOpenDialogUpload"
    />
    <v-dialog
      v-model="dialogUploadNewVerz"
      width="600px"
    >
      <v-card>
        <v-card-title>Neues Verzeichnis hochladen</v-card-title>
        <v-card-text>
          <div>
            <p style="font-size:80%;text-align:justify;" class="ma-2">
              Das Verzeichnis muss im .zip-Format hochgeladen werden.
            </p>
          </div>
          <v-file-input
            density="compact"
            label="Neues Verzeichnis (.zip)"
            accept=".zip"
            v-model="dialogUploadNewVerzNewZip"
          />
          <div class="d-flex justify-space-around">
            <div style="width:40%;"><v-btn block
              @click="dialogUploadNewVerz = false"
              color="grey"
              :disabled="loadingUploadVerz[`v${currentVerz.id * (-1)}`]"
            >Abbrechen</v-btn></div>
            <div style="width:40%;"><v-btn block
              :color="$vuetify.theme.current.colors['primary']"
              :style="`color:${$vuetify.theme.current.colors['primary-text']};`"
              :disabled="!dialogUploadNewVerzNewZip || dialogUploadNewVerzNewZip?.length <= 0"
              @click="tryUploadVerz(currentVerz.id * (-1), dialogUploadNewVerzNewZip[0]);"
              :loading="loadingUploadVerz[`v${currentVerz.id * (-1)}`]"
            >Speichern</v-btn></div>
          </div>
          <!-- <v-btn block
            :disabled="!dialogUploadNewVerzNewZip || dialogUploadNewVerzNewZip?.length <= 0"
            @click="tryUploadVerz(currentVerz.id * (-1), dialogUploadNewVerzNewZip[0]);"
            :loading="loadingUploadVerz[`v${currentVerz.id * (-1)}`]"
          >
            hochladen
          </v-btn> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <div align="center">
      <v-toolbar color="grey-lighten-2" style="max-width:1200px;" align="left">
        <v-btn
          v-if="idVerz > 0"
          icon
          :to="`/001-001-001?v=${currentVerz.idElternverzeichnis}`"
          :active="false"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ currentVerz.name }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon size="small" class="mx-2"
          @click="$store.dispatch('s001001/tryGetData')"
          :loading="$store.state.main.activeFetchs.length > 0"
        >
          <v-icon>mdi-sync</v-icon>
        </v-btn>
        <v-btn
          v-if="
            (
              $store.state.login.user.rechte.includes('001-001-001-001')
              || (
                idVerz > 0
                && (
                  currentVerz.bedingungen?.c001001001?.aendern.nutzer
                  || $store.state.login.user.rechte.includes('001-001-001-011')
                  || currentVerz.bedingungen?.c001001001?.Einstellungen_verwalten.nutzer
                  || $store.state.login.user.rechte.includes('001-001-001-012')
                )
              )
            ) && (
              !currentVerz.synchLocalUser?.idUser
            )
          "
          icon
          :style="`background-color:${$vuetify.theme.current.colors['primary']};color:${$vuetify.theme.current.colors['primary-text']};`"
          size="small"
        >
          <v-icon>mdi-plus</v-icon>
          <v-menu activator="parent">
            <v-list>
              <v-list-item
                v-if="$store.state.login.user.rechte.includes('001-001-001-001')"
                title="Verzeichnis erstellen"
                @click="doNeuVerz();"
              ></v-list-item>
              <v-list-item
                v-if="$store.state.login.user.rechte.includes('001-001-001-001')"
                title="Verzeichnis hochladen"
                @click="dialogUploadNewVerz = true"
              >
              </v-list-item>
              <v-list-item
                v-if="
                  idVerz > 0
                  && (
                    currentVerz.bedingungen?.c001001001?.aendern.nutzer
                    || $store.state.login.user.rechte.includes('001-001-001-011')
                    || currentVerz.bedingungen?.c001001001?.Einstellungen_verwalten.nutzer
                    || $store.state.login.user.rechte.includes('001-001-001-012')
                  )
                "
                @click="eventOpenDialogUpload++"
                title="Datei/en hochladen"
              ></v-list-item>
            </v-list>
          </v-menu>
        </v-btn>
      </v-toolbar>
    </div>
    <v-container fluid style="max-width:1200px;">
      <v-text-field
        v-if="
          (verzeichnisse.length + currentVerzDateien.length) >= 10
          || search !== ''
        "
        clearable
        v-model="search"
        label="suchen..."
      />
      <v-row no-gutters>
        <v-col cols="12" :md="currentVerz.id === 0 ? '12' : '6'"
          v-if="
            (
              verzeichnisse.length > 0
              || $vuetify.display.mdAndUp
            ) ||
            currentVerzDateien.length <= 0
          "
        >
          <p
            style="font-size:80%;"
          >
            Verzeichnisse
          </p>
          <p
            v-if="verzeichnisse.length <= 0"
            style="font-style:italic;"
            class="pa-4"
          >
            Keine Verzeichnisse gefunden
          </p>
          <v-list
            lines="one"
            density="compact"
          >
            <v-list-item
              v-for="verz in verzeichnisse"
              :key="verz.id"
            >
              <router-link
                :to="`/001-001-001?v=${verz.id}`"
                :style="`color:${$vuetify.theme.current.colors['text']};text-decoration:none;`"
              >
                <v-list-item-title>
                  <v-icon
                    start
                    :color="$vuetify.theme.current.colors['primary']"
                  >mdi-folder</v-icon>
                  {{ verz.name }}
                </v-list-item-title>
              </router-link>
              <template v-slot:append>
                <v-btn
                  v-if="$store.state.login.user.rechte.includes('001-001-001-012')"
                  icon size="small" color="rgba(0,0,0,0)" flat
                >
                  <v-icon>mdi-account-sync</v-icon>
                  <v-menu activator="parent" :close-on-content-click="false">
                    <v-card class="pa-2" style="max-width:340px;" align="center">
                      <p align="left" class="mb-2">
                        <b>Lokale Nutzersynchronisation</b>
                      </p>
                      <div v-if="[''].includes(verz.synchLocalUser.status)">
                        <p style="font-size:80%;text-align:justify;" class="mb-2">
                          Wenn das Verzeichnis einem Nutzer zugewiesen wird, kann auf dieses nicht weiter
                          zugegriffen werden. Lediglich der zugewiesene Nutzer hat die Möglichkeit das Verzeichnis
                          herunterzuladen und verändert wieder hochzuladen.<br>
                          Über einen lokalen Service kann dieser Synchronisations&shy;vorgang auch automatisiert durchgeführt werden.
                        </p>
                        <v-combobox
                          clearable
                          label="Nutzer wählen"
                          :items="$store.state.login.app.users"
                          item-title="name"
                          item-value="id"
                          v-model="verz.synchLocalUser.idUserCopy"
                          style="width:320px;max-width:100%;"
                        />
                        <v-btn block
                          @click="trySetLocalSync(verz.id, verz.synchLocalUser.idUserCopy.id, 'add');"
                          :loading="loadingSetLocalSync[`v${verz.id}`]"
                        >
                          zuweisen
                        </v-btn>
                      </div>
                      <div v-if="['sync', 'local', 'forceUpload', 'errorUpload-maxFileSize'].includes(verz.synchLocalUser.status)">
                        <div align="left" class="my-1 mb-2 d-flex justify-space-around">
                          <v-btn size="x-small" class="mx-1"
                            @click="tryUpdateSync(verz.id);"
                            :loading="loadingUpdateSync[`v${verz.id}`]"
                          >
                            <v-icon start>mdi-sync</v-icon>
                            Aktualisieren
                          </v-btn>
                          <v-btn size="x-small" class="mx-1">
                            <v-icon start>mdi-close-circle</v-icon>
                            Verbindung trennen
                            <v-dialog
                              v-model="dialogEndLocalSync[`v${verz.id}`]"
                              activator="parent"
                              width="600px"
                            >
                              <v-card>
                                <v-card-title>
                                  Synchronisation wirklich trennen?
                                </v-card-title>
                                <v-card-text>
                                  Die Verbindung wird getrennt, sodass die lokal vorgenommenen Änderungen nicht gesichert werden.<br />
                                  Ist ein lokaler Service installiert, werden die lokalen Dateien außerdem gelöscht.
                                </v-card-text>
                                <v-card-actions>
                                  <v-btn
                                    size="small"
                                    @click="dialogEndLocalSync[`v${verz.id}`] = false">
                                    Abbrechen
                                  </v-btn>
                                  <v-spacer />
                                  <v-btn
                                    size="small"
                                    :color="$vuetify.theme.current.colors['error']"
                                    @click="trySetLocalSync(verz.id, verz.synchLocalUser.idUser, 'remove');"
                                    :loading="loadingSetLocalSync[`v${verz.id}`]"
                                  >
                                    Trennen
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-btn>
                          <!-- <v-btn size="x-small" class="mx-1" @click="trySetLocalSync(verz.id, verz.synchLocalUser.idUser, 'remove');" :loading="loadingSetLocalSync[`v${verz.id}`]">
                            <v-icon start>mdi-close-circle</v-icon>
                            Verbindung trennen
                          </v-btn> -->
                        </div>
                        <p style="font-size:80%;text-align:justify;" class="mb-2">
                          Zugewiesen: <b>{{ $store.state.login.app.users.filter((o) => o.id === verz.synchLocalUser.idUser)[0]?.name }}</b>
                        </p>
                      </div>
                      <div v-if="['sync'].includes(verz.synchLocalUser.status)">
                        <p style="font-size:80%;text-align:justify;" class="mb-2">
                          Status: <b>Wird synchronisiert...</b>
                        </p>
                        <p style="font-size:80%;text-align:justify;" class="mb-2">
                          Der zugewiesene Nutzer hat jetzt die Möglichkeit das Verzeichnis herunter&shy;zuladen.
                        </p>
                      </div>
                      <div v-if="['local'].includes(verz.synchLocalUser.status)">
                        <p style="font-size:80%;text-align:justify;" class="mb-2">
                          Status: <b>Wird vom Nutzer bearbeitet...</b>
                        </p>
                        <p style="font-size:80%;text-align:justify;" class="mb-2">
                          Der zugewiesene Nutzer hat das Verzeichnis herunter&shy;geladen und kann die Inhalte bearbeiten.
                        </p>
                        <p style="font-size:80%;text-align:justify;" class="mb-2">
                          Wird der Bearbeitungsmodus jetzt beendet, wird der Nutzer aufgefordert, das neue Verzeichnis hochziladen.<br>
                          Ist ein lokaler Service installiert, lädt dieser die Dateien automatisch hoch.
                        </p>
                        <div align="left">
                          <v-btn size="x-small" class="mx-1" @click="trySetLocalSync(verz.id, verz.synchLocalUser.idUser, 'forceUpload');" :loading="loadingSetLocalSync[`v${verz.id}`]">
                            <v-icon start>mdi-close-circle</v-icon>
                            Bearbeitungsmodus beenden
                          </v-btn>
                        </div>
                      </div>
                      <div v-if="['forceUpload'].includes(verz.synchLocalUser.status)">
                        <p style="font-size:80%;text-align:justify;" class="mb-2">
                          Status: <b>Verzeichnis wird hochgeladen...</b>
                        </p>
                        <p style="font-size:80%;text-align:justify;" class="mb-2">
                          Der zugewiesene Nutzer wird aufgefordert, das Verzeichnis hochzuladen.<br>
                          Ist ein lokaler Service installiert, lädt dieser die Dateien automatisch hoch.
                        </p>
                      </div>
                      <div v-if="['errorUpload-maxFileSize'].includes(verz.synchLocalUser.status)">
                        <p style="font-size:80%;text-align:justify;" class="mb-2">
                          Status: <b :style="`color:${$vuetify.theme.current.colors['error']};`">Fehler beim Hochladen!</b>
                        </p>
                        <p style="font-size:80%;text-align:justify;" class="mb-2">
                          Die Daten konnten nicht hochgeladen werden. Dies könnte eine der folgenden Ursachen haben:
                        </p>
                        <div style="font-size:80%;text-align:left;position:relative;" class="mb-2 ps-4">
                          <div style="position:absolute;left:4px;top:0px;">-</div>
                          Die Gesamtgröße der Daten übersteigt das Limit von 100 MB
                        </div>
                        <div style="font-size:80%;text-align:left;position:relative;" class="mb-2 ps-4">
                          <div style="position:absolute;left:4px;top:0px;">-</div>
                          Das Verzeichnis enthält beschädigte Dateien
                        </div>
                        <div style="font-size:80%;text-align:left;position:relative;" class="mb-2 ps-4">
                          <div style="position:absolute;left:4px;top:0px;">-</div>
                          Der Computer wurde heruntergefahren oder die Netzwerkverbindung wurde getrennt, bevor der Upload vollendet wurde.
                        </div>
                        <div align="left">
                          <v-btn size="x-small" class="mx-1" @click="trySetLocalSync(verz.id, verz.synchLocalUser.idUser, 'forceUpload');" :loading="loadingSetLocalSync[`v${verz.id}`]">
                            <v-icon start>mdi-close-circle</v-icon>
                            Upload erneut versuchen
                          </v-btn>
                        </div>
                      </div>
                    </v-card>
                  </v-menu>
                </v-btn>
                <v-btn
                  v-if="
                    verz.synchLocalUser.idUser === $store.state.login.user.id
                  "
                  icon size="small" color="rgba(0,0,0,0)" flat
                >
                  <v-icon
                    v-if="['sync', 'local'].includes(verz.synchLocalUser.status)"
                  >mdi-sync</v-icon>
                  <v-icon
                    v-if="['forceUpload'].includes(verz.synchLocalUser.status)"
                    :color="$vuetify.theme.current.colors['error']"
                  >mdi-sync-alert</v-icon>
                  <v-menu activator="parent" :close-on-content-click="false">
                    <v-card class="pa-2" style="max-width:340px;" align="center">
                      <p align="left" class="mb-2">
                        <b>Lokale Nutzersynchronisation</b>
                      </p>
                      <div v-if="['sync', 'local'].includes(verz.synchLocalUser.status)">
                        <p style="font-size:80%;text-align:justify;" class="mb-2">
                          Das Verzeichnis wurde dir zugewiesen. Du kannst dieses herunter&shy;laden, die Inhalte
                          beliebig verändern und das Verzeichnis dann wieder hochladen.
                        </p>
                      </div>
                      <v-btn
                      v-if="['sync'].includes(verz.synchLocalUser.status)"
                        @click="tryDownloadVerz(verz.id, verz.name);"
                      >
                        <v-icon start>mdi-download</v-icon>
                        Herunterladen
                      </v-btn>
                      <div v-if="['forceUpload'].includes(verz.synchLocalUser.status)">
                        <p style="font-size:80%;text-align:justify;" class="mb-2">
                          Der Bearbeitungsmodus wurde beendet. Bitte jetzt das neue Verzeichnis hochladen.
                        </p>
                      </div>
                      <div v-if="['local', 'forceUpload'].includes(verz.synchLocalUser.status)">
                        <v-file-input
                          density="compact"
                          label="Neues Verzeichnis (.zip)"
                          accept=".zip"
                          v-model="verz.synchLocalUser.newZip"
                        />
                        <v-btn block
                          :disabled="!verz.synchLocalUser.newZip || verz.synchLocalUser.newZip?.length <= 0"
                          @click="tryUploadVerz(verz.id, verz.synchLocalUser.newZip[0]);"
                          :loading="loadingUploadVerz[`v${verz.id}`]"
                        >
                          hochladen
                        </v-btn>
                      </div>
                    </v-card>
                  </v-menu>
                </v-btn>
                <v-btn
                  icon size="small" color="rgba(0,0,0,0)" flat
                  v-if="
                    ( // Herunterladen
                      (
                        verz.bedingungen.c001001001.sehen.nutzer
                        || $store.state.login.user.rechte.includes('001-001-001-010')
                        || verz.bedingungen.c001001001.aendern.nutzer
                        || $store.state.login.user.rechte.includes('001-001-001-011')
                        || verz.bedingungen.c001001001.Einstellungen_verwalten.nutzer
                        || $store.state.login.user.rechte.includes('001-001-001-012')
                      ) && (
                        !verz.synchLocalUser.idUser
                        || verz.synchLocalUser.idUser === $store.state.login.user.id
                      )
                    ) || ( // Einstellungen
                      (
                        verz.bedingungen.c001001001.Einstellungen_verwalten.nutzer
                        || $store.state.login.user.rechte.includes('001-001-001-012')
                      ) && (
                        !verz.synchLocalUser.idUser
                      )
                    )
                  "
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                  <v-menu activator="parent">
                    <v-list>
                      <v-list-item
                        title="Herunterladen"
                        v-if="
                          (
                            verz.bedingungen.c001001001.sehen.nutzer
                            || $store.state.login.user.rechte.includes('001-001-001-010')
                            || verz.bedingungen.c001001001.aendern.nutzer
                            || $store.state.login.user.rechte.includes('001-001-001-011')
                            || verz.bedingungen.c001001001.Einstellungen_verwalten.nutzer
                            || $store.state.login.user.rechte.includes('001-001-001-012')
                          ) && (
                            !verz.synchLocalUser.idUser
                            || verz.synchLocalUser.idUser === $store.state.login.user.id
                          )
                        "
                        @click="tryDownloadVerz(verz.id, verz.name);"
                      ></v-list-item>
                      <v-list-item
                        title="Einstellungen"
                        v-if="
                          (
                            verz.bedingungen.c001001001.Einstellungen_verwalten.nutzer
                            || $store.state.login.user.rechte.includes('001-001-001-012')
                          ) && (
                            !verz.synchLocalUser.idUser
                          )
                        "
                        @click="doEditVerz(verz)"
                      ></v-list-item>
                    </v-list>
                  </v-menu>
                </v-btn>
              </template>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" md="6">
          <p
            v-if="currentVerz.id > 0"
            style="font-size:80%;"
          >
            Dateien
          </p>
          <p
            v-if="currentVerzDateien.length <= 0 && currentVerz.id > 0"
            style="font-style:italic;"
            class="pa-4"
          >
            Keine Dateien gefunden
          </p>
          <v-list
            lines="one"
            density="compact"
            v-if="currentVerz"
          >
            <v-list-item
              v-for="datei in currentVerzDateien"
              :key="datei.id"
            >
              <!-- <router-link
                :style="`color:${$vuetify.theme.current.colors['text']};text-decoration:none;`"
              > -->
                <v-list-item-title>
                  <v-icon
                    start
                    :color="datei.color ? datei.color : $vuetify.theme.current.colors['primary']"
                  >{{ datei.icon ? datei.icon : 'mdi-file' }}</v-icon>
                  {{ datei.name }}.{{ datei.typ }}
                </v-list-item-title>
              <!-- </router-link> -->
              <template v-slot:append>
                <v-btn
                  icon size="small" color="rgba(0,0,0,0)" flat
                  v-if="
                    ( // Herunterladen
                      currentVerz.bedingungen.c001001001.sehen.nutzer
                      || $store.state.login.user.rechte.includes('001-001-001-010')
                      || currentVerz.bedingungen.c001001001.aendern.nutzer
                      || $store.state.login.user.rechte.includes('001-001-001-011')
                      || currentVerz.bedingungen.c001001001.Einstellungen_verwalten.nutzer
                      || $store.state.login.user.rechte.includes('001-001-001-012')
                    ) || ( // Einstellungen
                      (
                        currentVerz.bedingungen.c001001001.aendern.nutzer
                        || $store.state.login.user.rechte.includes('001-001-001-011')
                        || currentVerz.bedingungen.c001001001.Einstellungen_verwalten.nutzer
                        || $store.state.login.user.rechte.includes('001-001-001-012')
                      ) && (
                        !currentVerz.synchLocalUser.idUser
                      )
                    )
                  "
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                  <v-menu activator="parent">
                    <v-list>
                      <v-list-item
                        title="Herunterladen"
                        v-if="
                          currentVerz.bedingungen.c001001001.sehen.nutzer
                          || $store.state.login.user.rechte.includes('001-001-001-010')
                          || currentVerz.bedingungen.c001001001.aendern.nutzer
                          || $store.state.login.user.rechte.includes('001-001-001-011')
                          || currentVerz.bedingungen.c001001001.Einstellungen_verwalten.nutzer
                          || $store.state.login.user.rechte.includes('001-001-001-012')
                        "
                        @click="tryDownload(datei.id, datei.name+'.'+datei.typ)"
                      ></v-list-item>
                      <v-list-item
                        title="Einstellungen"
                        v-if="
                          (
                            currentVerz.bedingungen.c001001001.aendern.nutzer
                            || $store.state.login.user.rechte.includes('001-001-001-011')
                            || currentVerz.bedingungen.c001001001.Einstellungen_verwalten.nutzer
                            || $store.state.login.user.rechte.includes('001-001-001-012')
                          ) && (
                            !currentVerz.synchLocalUser.idUser
                          )
                        "
                        @click="doEditFile(datei);"
                      ></v-list-item>
                    </v-list>
                  </v-menu>
                </v-btn>
              </template>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import c001001001EditVerz from '@/components/001-001/c001-001-001-editVerz.vue';
import c001001001EditFile from '@/components/001-001/c001-001-001-editFile.vue';
import c001001001Upload from '@/components/001-001/c001-001-001-upload.vue';

export default {
  name: 'c001001001Dateien',

  components: {
    c001001001EditVerz,
    c001001001EditFile,
    c001001001Upload,
  },
  props: {
    query: {
      type: Object,
      default() { return {} },
    },
  },
  data: () => ({
    dialogUploadNewVerz: false,
    dialogUploadNewVerzNewZip: [],
    dialogEndLocalSync: {},
    editVerz: undefined,
    editFile: undefined,
    eventOpenDialogUpload: 0,
    search: '',
    loadingSetLocalSync: {},
    loadingUpdateSync: {},
    loadingUploadVerz: {},
  }),
  computed: {
    verzeichnisse() {
      let verzeichnisse = this.$store.state.s001001.verzeichnisse.filter((o) => o.idElternverzeichnis === this.idVerz);
      if (this.search && this.search?.trim() !== '') {
        verzeichnisse = verzeichnisse.filter((o) => o.name.toLowerCase().includes(this.search.toLowerCase()));
      }
      return verzeichnisse;
    },
    idVerz() {
      let idVerz = 0;
      if (this.query.v) {
        if (this.$store.state.s001001.verzeichnisse.filter((o) => o.id === parseInt(this.query.v, 10)).length > 0) {
          idVerz = this.query.v;
        }
      }
      return parseInt(idVerz, 10);
    },
    currentVerz() {
      let currentVerz = {
        id: 0,
        name: 'Dateien',
        dateien: [],
      };
      if (this.$store.state.s001001.verzeichnisse.filter((o) => o.id === this.idVerz).length > 0) {
        currentVerz = this.$store.state.s001001.verzeichnisse.filter((o) => o.id === this.idVerz)[0];
      }
      return currentVerz;
    },
    currentVerzDateien() {
      let dateien = JSON.parse(JSON.stringify(this.currentVerz.dateien));
      if (this.search && this.search?.trim() !== '') {
        dateien = dateien.filter((o) => o.name.toLowerCase().includes(this.search.toLowerCase()));
      }
      return dateien;
    },
  },
  methods: {
    doNeuVerz() {
      const neuVerz = JSON.parse(JSON.stringify(this.$store.state.s001001.settings.neuVerzeichnis));
      neuVerz.idElternverzeichnis = this.idVerz;
      this.editVerz = neuVerz;
    },
    doEditVerz(verz) {
      this.editVerz = JSON.parse(JSON.stringify(verz));
    },
    doEditFile(file) {
      this.editFile = JSON.parse(JSON.stringify(file));
    },
    tryUpdateSync(idVerz) {
      this.loadingUpdateSync[`v${idVerz}`] = true;
      this.$store.dispatch('s001001/tryGetData').then(() => {
        this.loadingUpdateSync[`v${idVerz}`] = false;
      });
    },
    trySetLocalSync(idVerz, idUser, status) {
      this.loadingSetLocalSync[`v${idVerz}`] = true;
      this.$weFetch({
        path: '001-001/?001&setLocalSync',
        data: {
          idVerz,
          idUser,
          status,
        },
      }).then((res) => {
        this.$store.dispatch('s001001/tryGetData').then(() => {
          if (res.data.status === 'errorVerzAllreadySynced') {
            this.$store.commit('main/showSnackbar', {
              text: '<b>Achtung!</b> Es besteht bereits eine aktive Synchronisation dieses Verzeichnisses.',
              color: 'error',
            });
          }
          if (res.data.status === 'errorUserAllreadySynced') {
            this.$store.commit('main/showSnackbar', {
              text: '<b>Achtung!</b> Der gewählte Nutzer ist bereits einem Verzeichnis zugewiesen.',
              color: 'error',
            });
          }
          this.loadingSetLocalSync[`v${idVerz}`] = false;
          this.dialogEndLocalSync[`v${idVerz}`] = false;
        });
      });
    },
    async tryDownload(idFile, nameFile) {
      const date = new Date();
      const fetchId = date.getTime();
      this.$store.commit('main/startNewFetch', { id: fetchId });
      const body = new FormData();
      body.append('code', this.$store.state.login.user.code);
      body.append('id', idFile);
      await fetch(process.env.VUE_APP_API_HOST+'/001-001/?001&download', {
        method: 'post',
        body,
      })
        .then((response) => {
          this.$store.commit('main/endFetch', { id: date.getTime() });
          if (response.ok) {
            return response.blob();
          } else {
            throw new Error('Fehler beim Herunterladen der Datei.');
          }
        })
        .then((blob) => {
          // Erzeugen eines Download-Links für die heruntergeladene Datei
          var downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.download = nameFile; // Passe den Dateinamen entsprechend an
          downloadLink.style.display = 'none';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          this.$store.dispatch('s001001/tryGetData').then(() => {
            this.$store.commit('main/endFetch', { id: date.getTime() });
          });
        })
        .catch((error) => {
          console.error('Fehler: ', error);
        });
    },
    async tryDownloadVerz(idVerz, nameVerz) {
      const date = new Date();
      const fetchId = date.getTime();
      this.$store.commit('main/startNewFetch', { id: fetchId });
      const body = new FormData();
      body.append('code', this.$store.state.login.user.code);
      body.append('idVerz', idVerz);
      await fetch(process.env.VUE_APP_API_HOST+'/001-001/?001&downloadVerz', {
        method: 'post',
        body,
      })
        .then((response) => {
          this.$store.commit('main/endFetch', { id: date.getTime() });
          if (response.ok) {
            return response.blob();
          } else {
            throw new Error('Fehler beim Herunterladen der Datei.');
          }
        })
        .then((blob) => {
          // Erzeugen eines Download-Links für die heruntergeladene Datei
          var downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.download = nameVerz+'.zip'; // Passe den Dateinamen entsprechend an
          downloadLink.style.display = 'none';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          this.$store.dispatch('s001001/tryGetData').then(() => {
            this.$store.commit('main/endFetch', { id: date.getTime() });
          });
        })
        .catch((error) => {
          console.error('Fehler: ', error);
        });
    },
    tryUploadVerz(idVerz, zip) {
      this.loadingUploadVerz[`v${idVerz}`] = true;
      const data = {
        zip,
      };
      if (idVerz > 0) {
        data.idVerz = idVerz;
      } else {
        data.idElternVerz = idVerz * (-1);
      }
      this.$weFetch({
        path: '001-001/?001&uploadVerz',
        data,
        timeout: 300000,
      }).then((res) => {
        this.$store.dispatch('s001001/tryGetData').then(() => {
          /* if (res.data.status === 'errorVerzAllreadySynced') {
            this.$store.commit('main/showSnackbar', {
              text: '<b>Achtung!</b> Es besteht bereits eine aktive Synchronisation dieses Verzeichnisses.',
              color: 'error',
            });
          } */
          this.loadingUploadVerz[`v${idVerz}`] = false;
          this.dialogUploadNewVerz = false;
          if (res.data.neuVerzId) {
            console.log('navigiere zu: ', res.data.neuVerzId);
            this.$router.push(`/001-001-001?v=${res.data.neuVerzId}`);
          } else {
            console.log('navigiere zu: ', idVerz);
            this.$router.push(`/001-001-001?v=${idVerz}`);
          }
        });
      });
    },
  },
  created() {
  },
}
</script>