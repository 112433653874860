<template>
  <!--
    <003EditGrp
      v-model="grp"
      @update:modelValue="update()"
    />
  -->
  <div style="min-width:100%;">
    <v-tabs
      v-model="tabsDatenRechte"
      class="mb-4"
    >
      <v-tab value="nutzerdaten">Parameterdaten</v-tab>
      <v-tab value="einstellungen">Einstellungen</v-tab>
    </v-tabs>
    <v-row dense width="100%">
      <v-col v-if="tabsDatenRechte === 'nutzerdaten'">
        <v-row dense>
          <v-col cols="9">
            <v-text-field
              label="Parametername"
              v-model="value.name"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="#"
              v-model="value.sort"
            />
          </v-col>
          <v-col cols="12"
            v-if="$store.state.login.app.nutzerparameterTypen.length > 0"
          >
            <MainSelectParameterTyp v-model="value" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="tabsDatenRechte === 'einstellungen'" align="left">
      <!-- <p align="left" class="pa-2" style="font-size:80%;">
        Lorem ipsum
      </p> -->
      <v-row dense>
        <v-col cols="12" align="left">
          <v-checkbox
            label="Identifikationsparameter"
            v-model="value.ident"
          />
          <p class="mx-4" style="transform:translateY(-20px);font-size:80%;">
            Der Anzeigename jedes Benutzers setzt sich aus diesem und anderen Identifikationsparametern zusammen. Identifikationsparameter
            sind daher auch für alle sichtbar.
          </p>
        </v-col>
      </v-row>
      <mainSelectBedingungen
        v-if="value.bedingungen"
        v-model="value.bedingungen.c000005001"
        titel="Eigenes Profil"
        beschreibung="Wer darf diesen Parameter im eigenen Profil sehen bzw. die eigenen Werte bearbeiten?"
      />
    </div>
    <div class="d-flex justify-space-around">
      <div style="width:40%;"><v-btn block
        @click="$emit('cancel')"
        color="grey"
        :disabled="loadingSave"
      >Abbrechen</v-btn></div>
      <div style="width:40%;"><v-btn block
        @click="set()"
        :color="$vuetify.theme.current.colors['primary']"
        :style="`color:${$vuetify.theme.current.colors['primary-text']};`"
        :loading="loadingSave"
      >Speichern</v-btn></div>
    </div>
  </div>
</template>

<script>
import MainSelectParameterTyp from '@/components/main/mainSelectParameterTyp.vue';
import mainSelectBedingungen from '@/components/main/mainSelectBedingungen.vue';

export default {
  name: 'c000002002EditParam',
  
  components: {
    MainSelectParameterTyp,
    mainSelectBedingungen,
},
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    loadingSave: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tabsDatenRechte: undefined,
    value: '',
  }),
  watch: {
    modelValue: {
      handler(neu) {
        this.setValue(neu);
      },
      deep: true,
    },
  },
  methods: {
    setValue(value) {
      this.value = value;
    },
    set() {
      this.$emit('update:modelValue', this.value);
    },
  },
  created() {
    this.setValue(this.modelValue);
  },
}
</script>
