<template>
  <div>
    <v-tabs
      v-if="!$vuetify.display.mdAndUp"
      v-model="tabsEditResetPW"
    >
      <v-tab value="edit">Meine Daten</v-tab>
      <v-tab value="einstellungen">Einstellungen</v-tab>
    </v-tabs>
    <v-row class="mt-2">
      <v-col
        v-if="
          $vuetify.display.mdAndUp
          || (
            !$vuetify.display.mdAndUp
            && tabsEditResetPW === 'edit'
          )
        "
      >
        <p
          v-if="$vuetify.display.mdAndUp"
          class="ma-2 mb-4"
        >
          <b>Meine Daten</b>
        </p>
        <mainEditUser
          v-if="user"
          hideCancelButton
          view="000005001"
          :tabValue="['set'].includes(currentTab) ? currentTab : ''"
          v-model="user"
          :loadingSave="loadingSaveData"
          @update:modelValue="trySaveData()"
        />
      </v-col>
      <v-col
        v-if="
          $vuetify.display.mdAndUp
          || (
            !$vuetify.display.mdAndUp
            && tabsEditResetPW === 'einstellungen'
          )
        "
      >
        <p
          v-if="$vuetify.display.mdAndUp || 1 === 1"
          class="ma-2 mb-4"
        >
          <b>Passwort zurücksetzen</b>
        </p>
        <p :style="`font-size:80%;`" class="pa-2">
          Zum Zurücksetzen des Passworts muss das aktuell bestehende Passwort angegeben werden.
          Um sicherzugehen, dass das neue Passwort korrekt eingegeben wurde, muss dieses einmal
          wiederholt werden.
        </p>
        <div align="center">
          <v-text-field
            clearable
            type="password"
            label="Altes Passwort"
            v-model="resetPwAlt"
            :disabled="loadingResetPW"
          />
          <v-text-field
            clearable
            type="password"
            label="Neues Passwort"
            v-model="resetPwNeu"
            :disabled="loadingResetPW"
          />
          <v-text-field
            clearable
            type="password"
            label="Neues Passwort wiederholen"
            v-model="resetPwNeu2"
            :disabled="loadingResetPW"
          />
          <v-btn
            width="40%"
            @click="resetPW()"
            :color="$vuetify.theme.current.colors['primary']"
            :style="`color:${$vuetify.theme.current.colors['primary-text']};`"
            :loading="loadingResetPW"
          >zurücksetzen</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import mainEditUser from '@/components/main/mainEditUser.vue';

export default defineComponent({
  name: 'v000-005-MeinProfil',

  components: {
    mainEditUser,
  },
  data: () => ({
    user: undefined,
    loadingSaveData: false,
    tabsEditResetPW: null,
    loadingResetPW: false,
    resetPwNeu: '',
    resetPwAlt: '',
    resetPwNeu2: '',
  }),
  computed: {
    currentTab() {
      return this.$router.currentRoute.value.params.id;
    },
  },
  watch: {
  },
  methods: {
    trySaveData() {
      this.loadingSaveData = true;
      this.$weFetch({
        path: '000-005/?001&saveData',
        data: {
          obj: JSON.stringify(this.user),
        },
      }).then((res) => {
        if (res.status === 'success') {
          this.$weFetch({}).then(() => {
            this.loadingSaveData = false;
          });
        } else {
          this.loadingSaveData = false;
          if (res.status === `error${this.typCapitalizeFirstLetter}nameInvalid`) {
            this.$store.commit('main/showSnackbar', {
              text: '<b>Fehler!</b> Der gewählte Name ist zu kurz',
            });
          } else if (res.status === `error${this.typCapitalizeFirstLetter}nameNotAvailable`) {
            this.$store.commit('main/showSnackbar', {
              text: '<b>Fehler!</b> Der gewählte Name ist bereits vergeben',
            });
          }
        }
      });
    },
    resetPW() {
      if (this.resetPwNeu !== this.resetPwNeu2) {
        this.$store.commit('main/showSnackbar', {
          text: '<b>Fehler!</b> Die Wiederholung des neuen Passworts stimmt nicht überein.',
        });
      } else {
        this.loadingResetPW = true;
        this.$weFetch({
          path: '000-005/?001&resetPW',
          data: {
            pwAlt: this.resetPwAlt,
            pwNeu: this.resetPwNeu,
          },
        }).then((res) => {
          this.loadingResetPW = false;
          if (res.status === 'success') {
            this.$store.commit('main/showSnackbar', {
              text: 'Das Passwort wurde erfolgreich geändert!',
            });
            this.resetPWAlt = '';
            this.resetPwNeu = '';
            this.resetPwNeu2 = '';
          } else if (res.status === 'errorPwIncorrect') {
            this.$store.commit('main/showSnackbar', {
              text: '<b>Fehler!</b> das angegebene Passwort ist nicht korrekt.',
            });
          }
        });
      }
    },
  },
  created() {
    this.user = JSON.parse(JSON.stringify(this.$store.state.login.user));
    if (this.$router.currentRoute.value.query.setPW === null) {
      this.tabsEditResetPW = 'einstellungen';
    }
  },
});
</script>
